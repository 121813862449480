/*
Theme Name: 	Alchemy Starter 2021
Theme URI: 		https://alchemy.digital/
Description: 	Starter template for new sites
Version: 		3.0
Author: 		Alchemy Digital
Author URI: 	https://alchemy.digital/
Tags:
*/
/*================ UTILS ================*/
/*============================================================================
  Mixins
==============================================================================*/
/*============================================================================
  Mixin - Cover Image
==============================================================================*/
/*============================================================================
  Mixin - Input Placeholder Styles
==============================================================================*/
/*============================================================================
  Mixin - Responsive ratio

  Used for creating scalable elements that maintain the same ratio
  example:
  .element {
    @include responsive-ratio(400, 300);
  }
==============================================================================*/
/*============================================================================
  Mixin - Clear fix
==============================================================================*/
/*============================================================================
  Mixin - Visually Hidden
==============================================================================*/
/*============================================================================
  Mixin - Visually shown
==============================================================================*/
/*============================================================================
  Mixin - Media Queries
==============================================================================*/
/*============================================================================
  Mixin - Keyframes
==============================================================================*/
/*============================================================================
  Mixin - Animation
==============================================================================*/
/*============================================================================
  Mixin - Color Classes
==============================================================================*/
/*============================================================================
  Mixin - Double bg classes
  Removes margin and bottom padding so two module with same background colour look connected
==============================================================================*/
/*============================================================================
  Mixin - Grid
==============================================================================*/
/*============================================================================
  Mixin - Add gridsize to end of classes e.g. "--md" and puts it within the media query
  this is currently mostly used in _grid.scss
==============================================================================*/
/*============================================================================
  Mixin - Font size
==============================================================================*/
/*============================================================================
  Function - strip unit 
==============================================================================*/
/*============================================================================
  Color Variables
==============================================================================*/
/* ---- Colors ---- */
/* ---- Social colors ---- */
/* --- Font colors ---- */
/* --- Border colors ---- */
/* ---- Colors Array - used to create color classes such as "color-white" in typography.scss & layout.scss ---- */
/*============================================================================
  Typography Variables
==============================================================================*/
/* ---- Font line heights ---- */
/* ---- Font sizes ---- */
/* ---- Font weights ---- */
/* ---- Font families ---- */
/*============================================================================
  Padding & Margin Variables
==============================================================================*/
/* ---- Padding ---- */
/* ---- Margin ---- */
/* ---- Grid padding ---- */
/*============================================================================
  Grid Breakpoints and Class Names
==============================================================================*/
/*============================================================================
  Box Shadows
==============================================================================*/
/*============================================================================
  Easing & Transitions
==============================================================================*/
/*============================================================================
  Siteheader
==============================================================================*/
/*============================================================================
  Forms
==============================================================================*/
/*============================================================================
  Buttons
==============================================================================*/
/*============================================================================
  Carousels
==============================================================================*/
/*============================================================================
  BD margins & Page Max Width
==============================================================================*/
/*================ VENDOR ================*/
/* @import url(sass/vendor/owl/owl.carousel.min.css); */
/*================= BASE =================*/
/* ==========================================================================
   HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/* ==========================================================================
   Base
   ========================================================================== */
/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `font-family` inconsistency between `textarea` and other form
 * elements.
 */
html,
button,
input,
select,
textarea {
  font-family: sans-serif; }

/**
 * Address margins handled incorrectly in IE 6/7.
 */
body {
  margin: 0; }

/* ==========================================================================
   Links
   ========================================================================== */
/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: 0; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/* ==========================================================================
   Typography
   ========================================================================== */
/**
 * Address font sizes and margins set differently in IE 6/7.
 * Address font sizes within `section` and `article` in Firefox 4+, Safari 5,
 * and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

h2 {
  font-size: 1.5em;
  margin: 0.83em 0; }

h3 {
  font-size: 1.17em;
  margin: 1em 0; }

h4 {
  font-size: 1em;
  margin: 1.33em 0; }

h5 {
  font-size: 0.83em;
  margin: 1.67em 0; }

h6 {
  font-size: 0.67em;
  margin: 2.33em 0; }

/**
 * Address styling not present in IE 7/8/9, Safari 5, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
 */
b,
strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

/**
 * Address styling not present in Safari 5 and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address differences between Firefox and other browsers.
 * Known issue: no IE 6/7 normalization.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Address styling not present in IE 6/7/8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address margins set differently in IE 6/7.
 */
p,
pre {
  margin: 1em 0; }

/**
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  _font-family: "courier new", monospace;
  font-size: 1em; }

/**
 * Improve readability of pre-formatted text in all browsers.
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

/**
 * Address CSS quotes not supported in IE 6/7.
 */
q {
  quotes: none; }

/**
 * Address `quotes` property not supported in Safari 4.
 */
q:before,
q:after {
  content: "";
  content: none; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* ==========================================================================
   Lists
   ========================================================================== */
/**
 * Address margins set differently in IE 6/7.
 */
dl,
menu,
ol,
ul {
  margin: 1em 0; }

dd {
  margin: 0 0 0 40px; }

/**
 * Address paddings set differently in IE 6/7.
 */
menu,
ol,
ul {
  padding: 0 0 0 40px; }

/**
 * Correct list images handled incorrectly in IE 7.
 */
nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

/* ==========================================================================
   Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

img {
  max-width: 100%;
  height: auto; }

/**
 * Correct overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden; }

/* ==========================================================================
   Figures
   ========================================================================== */
/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/* ==========================================================================
   Forms
   ========================================================================== */
/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/* ==========================================================================
   Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* ==========================================================================
  animations -- animations.scss
  ========================================================================== */
/* ==========================================================================
  Accessibility - remove animations for "prefers reduced motion" -- animations.scss
  ========================================================================== */
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  * {
    -webkit-animation-duration: 0.001ms !important;
            animation-duration: 0.001ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    -webkit-transition-duration: 0.001ms !important;
            transition-duration: 0.001ms !important; } }
/* ==========================================================================
  Print styling - remove animations for print -- animations.scss
  ========================================================================== */
@media print {
  .effect {
    opacity: 1 !important;
    -webkit-transform: none !important;
        -ms-transform: none !important;
            transform: none !important; } }
/* ==========================================================================
  Main effect class -- animations.scss
  ========================================================================== */
.effect--default {
  opacity: 1;
  -webkit-transform: translate(0, 2em);
      -ms-transform: translate(0, 2em);
          transform: translate(0, 2em);
  -webkit-transition: opacity 250ms cubic-bezier(0.33, 1, 0.68, 1) 0s, -webkit-transform 250ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
  transition: opacity 250ms cubic-bezier(0.33, 1, 0.68, 1) 0s, -webkit-transform 250ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
  transition: opacity 250ms cubic-bezier(0.33, 1, 0.68, 1) 0s, transform 250ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
  transition: opacity 250ms cubic-bezier(0.33, 1, 0.68, 1) 0s, transform 250ms cubic-bezier(0.33, 1, 0.68, 1) 0s, -webkit-transform 250ms cubic-bezier(0.33, 1, 0.68, 1) 0s; }
  .effect--default.effect--ready, .no-cssanimations .effect--default {
    opacity: 1;
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0); }

/* ==========================================================================
  Custom effect classes -- animations.scss
  ========================================================================== */
.effect--fade {
  opacity: 0; }
  .effect--fade.effect--ready, .no-cssanimations .effect--fade {
    opacity: 1; }

.effect--slidein {
  -webkit-transform: translate(0, 2em);
      -ms-transform: translate(0, 2em);
          transform: translate(0, 2em); }

.effect--slidein.effect--ready,
.no-cssanimations .effect--slidein {
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0); }

.effect--slidedown {
  -webkit-transform: translate(0, -2em);
      -ms-transform: translate(0, -2em);
          transform: translate(0, -2em); }

.effect--slidedown.effect--ready,
.no-cssanimations .effect--slidedown {
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0); }

.effect--slideright {
  -webkit-transform: translate(-2em, 0);
      -ms-transform: translate(-2em, 0);
          transform: translate(-2em, 0); }

.effect--slideright.effect--ready,
.no-cssanimations .effect--slideright {
  -webkit-transform: translate(0, 0px);
      -ms-transform: translate(0, 0px);
          transform: translate(0, 0px); }

.effect--slideleft {
  -webkit-transform: translate(2em, 0px);
      -ms-transform: translate(2em, 0px);
          transform: translate(2em, 0px); }

.effect--slideleft.effect--ready,
.no-cssanimations .effect--slideleft {
  -webkit-transform: translate(0, 0px);
      -ms-transform: translate(0, 0px);
          transform: translate(0, 0px); }

/* ==========================================================================
  Effect delays -- animations.scss
  ========================================================================== */
.effect--delay-1 {
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms; }

.effect--delay-2 {
  -webkit-transition-delay: 300ms;
          transition-delay: 300ms; }

.effect--delay-3 {
  -webkit-transition-delay: 450ms;
          transition-delay: 450ms; }

.effect--delay-4 {
  -webkit-transition-delay: 600ms;
          transition-delay: 600ms; }

.effect--delay-5 {
  -webkit-transition-delay: 750ms;
          transition-delay: 750ms; }

.effect--delay-6 {
  -webkit-transition-delay: 900ms;
          transition-delay: 900ms; }

.effect--delay-7 {
  -webkit-transition-delay: 1050ms;
          transition-delay: 1050ms; }

.effect--delay-0 {
  -webkit-transition-delay: 0ms;
          transition-delay: 0ms; }

/* ==========================================================================
  Effect grid delays -- animations.scss
  ========================================================================== */
.effect.col-1-2:nth-child(2n + 1) {
  -webkit-transition-delay: 0ms;
          transition-delay: 0ms; }

.effect.col-1-2:nth-child(2n + 2) {
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms; }

.effect.col-1-3:nth-child(3n + 1) {
  -webkit-transition-delay: 0ms;
          transition-delay: 0ms; }

.effect.col-1-3:nth-child(3n + 2) {
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms; }

.effect.col-1-3:nth-child(3n + 3) {
  -webkit-transition-delay: calc(150ms * 2);
          transition-delay: calc(150ms * 2); }

.effect.col-1-4:nth-child(4n + 1) {
  -webkit-transition-delay: 0ms;
          transition-delay: 0ms; }

.effect.col-1-4:nth-child(4n + 2) {
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms; }

.effect.col-1-4:nth-child(4n + 3) {
  -webkit-transition-delay: calc(150ms * 2);
          transition-delay: calc(150ms * 2); }

.effect.col-1-4:nth-child(4n + 4) {
  -webkit-transition-delay: calc(150ms * 3);
          transition-delay: calc(150ms * 3); }

.effect.col-1-5:nth-child(5n + 1) {
  -webkit-transition-delay: 0ms;
          transition-delay: 0ms; }

.effect.col-1-5:nth-child(5n + 2) {
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms; }

.effect.col-1-5:nth-child(5n + 3) {
  -webkit-transition-delay: calc(150ms * 2);
          transition-delay: calc(150ms * 2); }

.effect.col-1-5:nth-child(5n + 4) {
  -webkit-transition-delay: calc(150ms * 3);
          transition-delay: calc(150ms * 3); }

.effect.col-1-5:nth-child(5n + 5) {
  -webkit-transition-delay: calc(150ms * 4);
          transition-delay: calc(150ms * 4); }

.effect.col-1-6:nth-child(6n + 1) {
  -webkit-transition-delay: 0ms;
          transition-delay: 0ms; }

.effect.col-1-6:nth-child(6n + 2) {
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms; }

.effect.col-1-6:nth-child(6n + 3) {
  -webkit-transition-delay: calc(150ms * 2);
          transition-delay: calc(150ms * 2); }

.effect.col-1-6:nth-child(6n + 4) {
  -webkit-transition-delay: calc(150ms * 3);
          transition-delay: calc(150ms * 3); }

.effect.col-1-6:nth-child(6n + 5) {
  -webkit-transition-delay: calc(150ms * 4);
          transition-delay: calc(150ms * 4); }

.effect.col-1-6:nth-child(6n + 6) {
  -webkit-transition-delay: calc(150ms * 5);
          transition-delay: calc(150ms * 5); }

@media only screen and (min-width: 550px) {
  .effect.col-sm-1-2:nth-child(2n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-sm-1-2:nth-child(2n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-sm-1-3:nth-child(3n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-sm-1-3:nth-child(3n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-sm-1-3:nth-child(3n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-sm-1-4:nth-child(4n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-sm-1-4:nth-child(4n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-sm-1-4:nth-child(4n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-sm-1-4:nth-child(4n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-sm-1-5:nth-child(5n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-sm-1-5:nth-child(5n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-sm-1-5:nth-child(5n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-sm-1-5:nth-child(5n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-sm-1-5:nth-child(5n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-sm-1-6:nth-child(6n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-sm-1-6:nth-child(6n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-sm-1-6:nth-child(6n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-sm-1-6:nth-child(6n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-sm-1-6:nth-child(6n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-sm-1-6:nth-child(6n + 6) {
    -webkit-transition-delay: calc(150ms * 5);
            transition-delay: calc(150ms * 5); } }
@media only screen and (min-width: 700px) {
  .effect.col-md-1-2:nth-child(2n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-md-1-2:nth-child(2n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-md-1-3:nth-child(3n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-md-1-3:nth-child(3n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-md-1-3:nth-child(3n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-md-1-4:nth-child(4n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-md-1-4:nth-child(4n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-md-1-4:nth-child(4n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-md-1-4:nth-child(4n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-md-1-5:nth-child(5n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-md-1-5:nth-child(5n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-md-1-5:nth-child(5n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-md-1-5:nth-child(5n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-md-1-5:nth-child(5n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-md-1-6:nth-child(6n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-md-1-6:nth-child(6n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-md-1-6:nth-child(6n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-md-1-6:nth-child(6n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-md-1-6:nth-child(6n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-md-1-6:nth-child(6n + 6) {
    -webkit-transition-delay: calc(150ms * 5);
            transition-delay: calc(150ms * 5); } }
@media only screen and (min-width: 1000px) {
  .effect.col-lg-1-2:nth-child(2n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-lg-1-2:nth-child(2n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-lg-1-3:nth-child(3n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-lg-1-3:nth-child(3n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-lg-1-3:nth-child(3n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-lg-1-4:nth-child(4n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-lg-1-4:nth-child(4n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-lg-1-4:nth-child(4n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-lg-1-4:nth-child(4n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-lg-1-5:nth-child(5n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-lg-1-5:nth-child(5n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-lg-1-5:nth-child(5n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-lg-1-5:nth-child(5n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-lg-1-5:nth-child(5n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-lg-1-6:nth-child(6n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-lg-1-6:nth-child(6n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-lg-1-6:nth-child(6n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-lg-1-6:nth-child(6n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-lg-1-6:nth-child(6n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-lg-1-6:nth-child(6n + 6) {
    -webkit-transition-delay: calc(150ms * 5);
            transition-delay: calc(150ms * 5); } }
@media only screen and (min-width: 1200px) {
  .effect.col-xl-1-2:nth-child(2n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-xl-1-2:nth-child(2n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-xl-1-3:nth-child(3n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-xl-1-3:nth-child(3n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-xl-1-3:nth-child(3n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-xl-1-4:nth-child(4n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-xl-1-4:nth-child(4n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-xl-1-4:nth-child(4n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-xl-1-4:nth-child(4n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-xl-1-5:nth-child(5n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-xl-1-5:nth-child(5n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-xl-1-5:nth-child(5n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-xl-1-5:nth-child(5n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-xl-1-5:nth-child(5n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-xl-1-6:nth-child(6n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-xl-1-6:nth-child(6n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-xl-1-6:nth-child(6n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-xl-1-6:nth-child(6n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-xl-1-6:nth-child(6n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-xl-1-6:nth-child(6n + 6) {
    -webkit-transition-delay: calc(150ms * 5);
            transition-delay: calc(150ms * 5); } }
@media only screen and (min-width: 1400px) {
  .effect.col-ws-1-2:nth-child(2n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-ws-1-2:nth-child(2n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-ws-1-3:nth-child(3n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-ws-1-3:nth-child(3n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-ws-1-3:nth-child(3n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-ws-1-4:nth-child(4n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-ws-1-4:nth-child(4n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-ws-1-4:nth-child(4n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-ws-1-4:nth-child(4n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-ws-1-5:nth-child(5n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-ws-1-5:nth-child(5n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-ws-1-5:nth-child(5n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-ws-1-5:nth-child(5n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-ws-1-5:nth-child(5n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-ws-1-6:nth-child(6n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-ws-1-6:nth-child(6n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-ws-1-6:nth-child(6n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-ws-1-6:nth-child(6n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-ws-1-6:nth-child(6n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-ws-1-6:nth-child(6n + 6) {
    -webkit-transition-delay: calc(150ms * 5);
            transition-delay: calc(150ms * 5); } }
@media only screen and (min-width: 1600px) {
  .effect.col-xw-1-2:nth-child(2n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-xw-1-2:nth-child(2n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-xw-1-3:nth-child(3n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-xw-1-3:nth-child(3n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-xw-1-3:nth-child(3n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-xw-1-4:nth-child(4n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-xw-1-4:nth-child(4n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-xw-1-4:nth-child(4n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-xw-1-4:nth-child(4n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-xw-1-5:nth-child(5n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-xw-1-5:nth-child(5n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-xw-1-5:nth-child(5n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-xw-1-5:nth-child(5n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-xw-1-5:nth-child(5n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-xw-1-6:nth-child(6n + 1) {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }

  .effect.col-xw-1-6:nth-child(6n + 2) {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }

  .effect.col-xw-1-6:nth-child(6n + 3) {
    -webkit-transition-delay: calc(150ms * 2);
            transition-delay: calc(150ms * 2); }

  .effect.col-xw-1-6:nth-child(6n + 4) {
    -webkit-transition-delay: calc(150ms * 3);
            transition-delay: calc(150ms * 3); }

  .effect.col-xw-1-6:nth-child(6n + 5) {
    -webkit-transition-delay: calc(150ms * 4);
            transition-delay: calc(150ms * 4); }

  .effect.col-xw-1-6:nth-child(6n + 6) {
    -webkit-transition-delay: calc(150ms * 5);
            transition-delay: calc(150ms * 5); } }
/*  ==========================================================================
	Base typography -- typography.scss
	========================================================================== */
html {
  /* stops fonts displaying bolder on firefox */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

/*  ==========================================================================
	Heading Styles  -- typography.scss
	========================================================================== */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.25em;
  line-height: 1.2;
  font-weight: 600; }

h1,
.heading-huge {
  font-family: "adobe-garamond-pro", serif;
  font-size: 36px;
  line-height: 1; }
  @media only screen and (min-width: 550px) {
    h1,
    .heading-huge {
      font-size: 38px; } }
  @media only screen and (min-width: 700px) {
    h1,
    .heading-huge {
      font-size: 42px; } }
  @media only screen and (min-width: 1000px) {
    h1,
    .heading-huge {
      font-size: 52px; } }
  @media only screen and (min-width: 1200px) {
    h1,
    .heading-huge {
      font-size: 61px; } }
  @media only screen and (min-width: 1400px) {
    h1,
    .heading-huge {
      font-size: 72px; } }
  @media only screen and (min-width: 1600px) {
    h1,
    .heading-huge {
      font-size: 85px; } }
  @media only screen and (min-width: 1800px) {
    h1,
    .heading-huge {
      font-size: 100px; } }

h2,
.heading-large {
  font-family: "adobe-garamond-pro", serif;
  font-size: 32px;
  line-height: 1.1; }
  @media only screen and (min-width: 550px) {
    h2,
    .heading-large {
      font-size: 33px; } }
  @media only screen and (min-width: 700px) {
    h2,
    .heading-large {
      font-size: 35px; } }
  @media only screen and (min-width: 1000px) {
    h2,
    .heading-large {
      font-size: 39px; } }
  @media only screen and (min-width: 1200px) {
    h2,
    .heading-large {
      font-size: 44px; } }
  @media only screen and (min-width: 1400px) {
    h2,
    .heading-large {
      font-size: 49px; } }
  @media only screen and (min-width: 1600px) {
    h2,
    .heading-large {
      font-size: 55px; } }
  @media only screen and (min-width: 1800px) {
    h2,
    .heading-large {
      font-size: 62px; } }

h3,
.heading-med {
  font-family: "adobe-garamond-pro", serif;
  font-size: 26px;
  line-height: 1.3; }
  @media only screen and (min-width: 550px) {
    h3,
    .heading-med {
      font-size: 26px; } }
  @media only screen and (min-width: 700px) {
    h3,
    .heading-med {
      font-size: 27px; } }
  @media only screen and (min-width: 1000px) {
    h3,
    .heading-med {
      font-size: 29px; } }
  @media only screen and (min-width: 1200px) {
    h3,
    .heading-med {
      font-size: 31px; } }
  @media only screen and (min-width: 1400px) {
    h3,
    .heading-med {
      font-size: 33px; } }
  @media only screen and (min-width: 1600px) {
    h3,
    .heading-med {
      font-size: 35px; } }
  @media only screen and (min-width: 1800px) {
    h3,
    .heading-med {
      font-size: 38px; } }

h4,
.heading-reg {
  font-family: "sofia-pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3; }
  @media only screen and (min-width: 550px) {
    h4,
    .heading-reg {
      font-size: 16px; } }
  @media only screen and (min-width: 700px) {
    h4,
    .heading-reg {
      font-size: 16px; } }
  @media only screen and (min-width: 1000px) {
    h4,
    .heading-reg {
      font-size: 17px; } }
  @media only screen and (min-width: 1200px) {
    h4,
    .heading-reg {
      font-size: 18px; } }
  @media only screen and (min-width: 1400px) {
    h4,
    .heading-reg {
      font-size: 19px; } }
  @media only screen and (min-width: 1600px) {
    h4,
    .heading-reg {
      font-size: 20px; } }
  @media only screen and (min-width: 1800px) {
    h4,
    .heading-reg {
      font-size: 21px; } }

h5,
.heading-sml {
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4; }
  @media only screen and (min-width: 550px) {
    h5,
    .heading-sml {
      font-size: 14px; } }
  @media only screen and (min-width: 700px) {
    h5,
    .heading-sml {
      font-size: 14px; } }
  @media only screen and (min-width: 1000px) {
    h5,
    .heading-sml {
      font-size: 15px; } }
  @media only screen and (min-width: 1200px) {
    h5,
    .heading-sml {
      font-size: 15px; } }
  @media only screen and (min-width: 1400px) {
    h5,
    .heading-sml {
      font-size: 15px; } }
  @media only screen and (min-width: 1600px) {
    h5,
    .heading-sml {
      font-size: 16px; } }
  @media only screen and (min-width: 1800px) {
    h5,
    .heading-sml {
      font-size: 17px; } }

h6,
.heading-xsml {
  font-family: "sofia-pro", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5; }
  @media only screen and (min-width: 550px) {
    h6,
    .heading-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 700px) {
    h6,
    .heading-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 1000px) {
    h6,
    .heading-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 1200px) {
    h6,
    .heading-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 1400px) {
    h6,
    .heading-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 1600px) {
    h6,
    .heading-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 1800px) {
    h6,
    .heading-xsml {
      font-size: 12px; } }

/*  ==========================================================================
	Font sizes  -- typography.scss
	========================================================================== */
.text-huge {
  font-size: 36px;
  line-height: 1; }
  @media only screen and (min-width: 550px) {
    .text-huge {
      font-size: 38px; } }
  @media only screen and (min-width: 700px) {
    .text-huge {
      font-size: 42px; } }
  @media only screen and (min-width: 1000px) {
    .text-huge {
      font-size: 52px; } }
  @media only screen and (min-width: 1200px) {
    .text-huge {
      font-size: 61px; } }
  @media only screen and (min-width: 1400px) {
    .text-huge {
      font-size: 72px; } }
  @media only screen and (min-width: 1600px) {
    .text-huge {
      font-size: 85px; } }
  @media only screen and (min-width: 1800px) {
    .text-huge {
      font-size: 100px; } }

.text-large {
  font-size: 32px;
  line-height: 1.1; }
  @media only screen and (min-width: 550px) {
    .text-large {
      font-size: 33px; } }
  @media only screen and (min-width: 700px) {
    .text-large {
      font-size: 35px; } }
  @media only screen and (min-width: 1000px) {
    .text-large {
      font-size: 39px; } }
  @media only screen and (min-width: 1200px) {
    .text-large {
      font-size: 44px; } }
  @media only screen and (min-width: 1400px) {
    .text-large {
      font-size: 49px; } }
  @media only screen and (min-width: 1600px) {
    .text-large {
      font-size: 55px; } }
  @media only screen and (min-width: 1800px) {
    .text-large {
      font-size: 62px; } }

.text-med {
  font-size: 26px;
  line-height: 1.3; }
  @media only screen and (min-width: 550px) {
    .text-med {
      font-size: 26px; } }
  @media only screen and (min-width: 700px) {
    .text-med {
      font-size: 27px; } }
  @media only screen and (min-width: 1000px) {
    .text-med {
      font-size: 29px; } }
  @media only screen and (min-width: 1200px) {
    .text-med {
      font-size: 31px; } }
  @media only screen and (min-width: 1400px) {
    .text-med {
      font-size: 33px; } }
  @media only screen and (min-width: 1600px) {
    .text-med {
      font-size: 35px; } }
  @media only screen and (min-width: 1800px) {
    .text-med {
      font-size: 38px; } }

.text-reg,
body {
  font-size: 16px;
  line-height: 1.5; }
  @media only screen and (min-width: 550px) {
    .text-reg,
    body {
      font-size: 16px; } }
  @media only screen and (min-width: 700px) {
    .text-reg,
    body {
      font-size: 16px; } }
  @media only screen and (min-width: 1000px) {
    .text-reg,
    body {
      font-size: 17px; } }
  @media only screen and (min-width: 1200px) {
    .text-reg,
    body {
      font-size: 18px; } }
  @media only screen and (min-width: 1400px) {
    .text-reg,
    body {
      font-size: 19px; } }
  @media only screen and (min-width: 1600px) {
    .text-reg,
    body {
      font-size: 20px; } }
  @media only screen and (min-width: 1800px) {
    .text-reg,
    body {
      font-size: 21px; } }

.text-sml, .btn, .text-link, .nf-form-cont label, .nf-form-cont .nf-error-msg, .contentarea .wp-caption .wp-caption-text, .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list {
  font-size: 14px; }
  @media only screen and (min-width: 550px) {
    .text-sml, .btn, .text-link, .nf-form-cont label, .nf-form-cont .nf-error-msg, .contentarea .wp-caption .wp-caption-text, .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list {
      font-size: 14px; } }
  @media only screen and (min-width: 700px) {
    .text-sml, .btn, .text-link, .nf-form-cont label, .nf-form-cont .nf-error-msg, .contentarea .wp-caption .wp-caption-text, .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list {
      font-size: 14px; } }
  @media only screen and (min-width: 1000px) {
    .text-sml, .btn, .text-link, .nf-form-cont label, .nf-form-cont .nf-error-msg, .contentarea .wp-caption .wp-caption-text, .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list {
      font-size: 15px; } }
  @media only screen and (min-width: 1200px) {
    .text-sml, .btn, .text-link, .nf-form-cont label, .nf-form-cont .nf-error-msg, .contentarea .wp-caption .wp-caption-text, .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list {
      font-size: 15px; } }
  @media only screen and (min-width: 1400px) {
    .text-sml, .btn, .text-link, .nf-form-cont label, .nf-form-cont .nf-error-msg, .contentarea .wp-caption .wp-caption-text, .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list {
      font-size: 15px; } }
  @media only screen and (min-width: 1600px) {
    .text-sml, .btn, .text-link, .nf-form-cont label, .nf-form-cont .nf-error-msg, .contentarea .wp-caption .wp-caption-text, .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list {
      font-size: 16px; } }
  @media only screen and (min-width: 1800px) {
    .text-sml, .btn, .text-link, .nf-form-cont label, .nf-form-cont .nf-error-msg, .contentarea .wp-caption .wp-caption-text, .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list {
      font-size: 17px; } }

.text-xsml {
  font-size: 12px; }
  @media only screen and (min-width: 550px) {
    .text-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 700px) {
    .text-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 1000px) {
    .text-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 1200px) {
    .text-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 1400px) {
    .text-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 1600px) {
    .text-xsml {
      font-size: 12px; } }
  @media only screen and (min-width: 1800px) {
    .text-xsml {
      font-size: 12px; } }

p {
  margin: 0 0 1em; }

/*  ==========================================================================
	Font families  -- typography.scss
	========================================================================== */
.font-body {
  font-family: "sofia-pro", sans-serif; }

.font-header {
  font-family: "adobe-garamond-pro", serif;
  font-weight: 400; }

/*  ==========================================================================
	Font weights -- typography.scss
	========================================================================== */
.font-bold, .text-link, .nf-form-cont label, .nf-form-cont .nf-error-msg,
b,
strong {
  font-weight: 600; }

.font-semibold {
  font-weight: 600; }

.font-reg, .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list {
  font-weight: 400; }

.font-light {
  font-weight: 200; }

/*  ==========================================================================
	Font Cases -- typography.scss
	========================================================================== */
.case-lower {
  text-transform: lowercase; }

.case-upper {
  text-transform: uppercase; }

/*  ==========================================================================
	Text alignments -- typography.scss
	========================================================================== */
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

/*  ==========================================================================
	Text colors -- typography.scss
	========================================================================== */
.color-gold {
  color: #d9a00e; }
.color-light-gold {
  color: rgba(217, 160, 14, 0.1); }
.color-white {
  color: #ffffff; }
.color-black {
  color: #000000; }
.color-grey {
  color: #888; }

.accent-gold .color-accent {
  color: #d9a00e; }
.accent-light-gold .color-accent {
  color: rgba(217, 160, 14, 0.1); }
.accent-white .color-accent {
  color: #ffffff; }
.accent-black .color-accent {
  color: #000000; }
.accent-grey .color-accent {
  color: #888; }

/*  ==========================================================================
	Opacities -- typography.scss
	========================================================================== */
.o-33 {
  opacity: 0.33; }

.o-50, .contentarea .wp-caption .wp-caption-text {
  opacity: 0.5; }

/*  ==========================================================================
	List styles -- typography.scss
	========================================================================== */
ol,
ul {
  margin: 0 0 1em;
  padding: 0; }
  ol.list-style-none,
  ul.list-style-none {
    margin-left: 0;
    list-style: none; }

.inlinenavlist {
  margin: 0 -0.75em; }
  .inlinenavlist li {
    display: inline-block;
    margin: 0;
    padding: 0; }
    .inlinenavlist li a {
      display: block;
      margin: 0 0.75em; }

/*  ==========================================================================
    links -- links-buttons.scss
    ========================================================================== */
a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit; }

.linkline {
  background-image: -webkit-linear-gradient(left, black 0%, black 50%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.15) 100%);
  background-image: linear-gradient(90deg, black 0%, black 50%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.15) 100%);
  background-size: 200% 1px;
  background-position: 100% bottom;
  background-repeat: no-repeat;
  -webkit-transition: background-position 250ms cubic-bezier(0.33, 1, 0.68, 1);
  transition: background-position 250ms cubic-bezier(0.33, 1, 0.68, 1); }
  a:hover .linkline {
    background-position: 0 bottom; }
  .color-white .linkline {
    background-image: -webkit-linear-gradient(left, white 0%, white 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 100%);
    background-image: linear-gradient(90deg, white 0%, white 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 100%); }
  .hiddenlinkline .linkline {
    background-image: -webkit-linear-gradient(left, black 0%, black 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
    background-image: linear-gradient(90deg, black 0%, black 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%); }
  .color-white.hiddenlinkline .linkline, .hiddenlinkline .color-white .linkline, .color-white .hiddenlinkline .linkline {
    background-image: -webkit-linear-gradient(left, white 0%, white 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(90deg, white 0%, white 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%); }

.accent-gold .linkline {
  background-image: -webkit-linear-gradient(left, #d9a00e 0%, #d9a00e 50%, rgba(217, 160, 14, 0.15) 50%, rgba(217, 160, 14, 0.15) 100%);
  background-image: linear-gradient(90deg, #d9a00e 0%, #d9a00e 50%, rgba(217, 160, 14, 0.15) 50%, rgba(217, 160, 14, 0.15) 100%); }
.accent-gold .hiddenlinkline .linkline, .hiddenlinkline .accent-gold {
  background-image: -webkit-linear-gradient(left, #d9a00e 0%, #d9a00e 50%, rgba(217, 160, 14, 0) 50%, rgba(217, 160, 14, 0) 100%);
  background-image: linear-gradient(90deg, #d9a00e 0%, #d9a00e 50%, rgba(217, 160, 14, 0) 50%, rgba(217, 160, 14, 0) 100%); }
.accent-light-gold .linkline {
  background-image: -webkit-linear-gradient(left, #d9a00e 0%, #d9a00e 50%, rgba(217, 160, 14, 0.15) 50%, rgba(217, 160, 14, 0.15) 100%);
  background-image: linear-gradient(90deg, #d9a00e 0%, #d9a00e 50%, rgba(217, 160, 14, 0.15) 50%, rgba(217, 160, 14, 0.15) 100%); }
.accent-light-gold .hiddenlinkline .linkline, .hiddenlinkline .accent-light-gold {
  background-image: -webkit-linear-gradient(left, #d9a00e 0%, #d9a00e 50%, rgba(217, 160, 14, 0) 50%, rgba(217, 160, 14, 0) 100%);
  background-image: linear-gradient(90deg, #d9a00e 0%, #d9a00e 50%, rgba(217, 160, 14, 0) 50%, rgba(217, 160, 14, 0) 100%); }
.accent-white .linkline {
  background-image: -webkit-linear-gradient(left, white 0%, white 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 100%);
  background-image: linear-gradient(90deg, white 0%, white 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 100%); }
.accent-white .hiddenlinkline .linkline, .hiddenlinkline .accent-white {
  background-image: -webkit-linear-gradient(left, white 0%, white 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(90deg, white 0%, white 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%); }
.accent-black .linkline {
  background-image: -webkit-linear-gradient(left, black 0%, black 50%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.15) 100%);
  background-image: linear-gradient(90deg, black 0%, black 50%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.15) 100%); }
.accent-black .hiddenlinkline .linkline, .hiddenlinkline .accent-black {
  background-image: -webkit-linear-gradient(left, black 0%, black 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
  background-image: linear-gradient(90deg, black 0%, black 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%); }
.accent-grey .linkline {
  background-image: -webkit-linear-gradient(left, #888888 0%, #888888 50%, rgba(136, 136, 136, 0.15) 50%, rgba(136, 136, 136, 0.15) 100%);
  background-image: linear-gradient(90deg, #888888 0%, #888888 50%, rgba(136, 136, 136, 0.15) 50%, rgba(136, 136, 136, 0.15) 100%); }
.accent-grey .hiddenlinkline .linkline, .hiddenlinkline .accent-grey {
  background-image: -webkit-linear-gradient(left, #888888 0%, #888888 50%, rgba(136, 136, 136, 0) 50%, rgba(136, 136, 136, 0) 100%);
  background-image: linear-gradient(90deg, #888888 0%, #888888 50%, rgba(136, 136, 136, 0) 50%, rgba(136, 136, 136, 0) 100%); }

/*  ==========================================================================
    Link Icons -- links-buttons.scss
    ========================================================================== */
a .fa,
a .fab,
a .fal,
a .far,
a .fas,
.btn .fa,
.btn .fab,
.btn .fal,
.btn .far,
.btn .fas {
  padding: 0 0 0 0.66em; }
  a .fa--left,
  a .fab--left,
  a .fal--left,
  a .far--left,
  a .fas--left,
  .btn .fa--left,
  .btn .fab--left,
  .btn .fal--left,
  .btn .far--left,
  .btn .fas--left {
    padding: 0 0.66em 0 0; }
  a .fa--nopad,
  a .fab--nopad,
  a .fal--nopad,
  a .far--nopad,
  a .fas--nopad,
  .btn .fa--nopad,
  .btn .fab--nopad,
  .btn .fal--nopad,
  .btn .far--nopad,
  .btn .fas--nopad {
    padding: 0; }

/*  ==========================================================================
    Buttons -- links-buttons.scss
    ========================================================================== */
.btn {
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  border-radius: 0.25em;
  display: inline-block;
  margin: 0 0.75em 0.75em 0;
  padding: 1em 1.5em;
  border: none;
  font-weight: 600;
  -webkit-transition: background-color 150ms cubic-bezier(0.65, 0, 0.35, 1), color 150ms cubic-bezier(0.65, 0, 0.35, 1);
  transition: background-color 150ms cubic-bezier(0.65, 0, 0.35, 1), color 150ms cubic-bezier(0.65, 0, 0.35, 1); }
  .btn .linkline {
    border: none;
    background-image: none; }
  .btn:hover {
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff; }
  .color-white .btn {
    background: #ffffff;
    color: #000000; }
    .color-white .btn:hover {
      background-color: rgba(255, 255, 255, 0.75);
      color: #000000; }
  .btn--secondary {
    background: rgba(0, 0, 0, 0.1);
    color: #000000; }
    .btn--secondary:hover {
      background: rgba(0, 0, 0, 0.2);
      color: #000000; }
    .color-white .btn--secondary {
      background: rgba(255, 255, 255, 0.15);
      color: #ffffff; }
      .color-white .btn--secondary:hover {
        background: rgba(255, 255, 255, 0.25);
        color: #ffffff; }
  .btn--gold {
    background: #d9a00e;
    color: #ffffff; }
    .btn--gold:hover {
      background: #c18e0c;
      color: #ffffff; }
  .btn--light-gold {
    background: rgba(217, 160, 14, 0.1);
    color: #ffffff; }
    .btn--light-gold:hover {
      background: rgba(193, 142, 12, 0.1);
      color: #ffffff; }
  .btn--white {
    background: #ffffff;
    color: #ffffff; }
    .btn--white:hover {
      background: #f2f2f2;
      color: #ffffff; }
  .btn--black {
    background: #000000;
    color: #ffffff; }
    .btn--black:hover {
      background: black;
      color: #ffffff; }
  .btn--grey {
    background: #888;
    color: #ffffff; }
    .btn--grey:hover {
      background: #7b7b7b;
      color: #ffffff; }

.accent-gold .btn--primary {
  background-color: #d9a00e; }
  .accent-gold .btn--primary:hover {
    background-color: #c18e0c; }
.accent-light-gold .btn--primary {
  background-color: rgba(217, 160, 14, 0.1); }
  .accent-light-gold .btn--primary:hover {
    background-color: rgba(193, 142, 12, 0.1); }
.accent-white .btn--primary {
  background-color: #ffffff; }
  .accent-white .btn--primary:hover {
    background-color: #f2f2f2; }
.accent-black .btn--primary {
  background-color: #000000; }
  .accent-black .btn--primary:hover {
    background-color: black; }
.accent-grey .btn--primary {
  background-color: #888; }
  .accent-grey .btn--primary:hover {
    background-color: #7b7b7b; }

/*  ==========================================================================
    Text Links -- links-buttons.scss
    ========================================================================== */
.text-link:after {
  content: "\f178";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-style: normal;
  margin-left: 0.5em;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased; }

/*  ==========================================================================
    share icons -- links-buttons.scss
    ========================================================================== */
.socialicons {
  position: relative;
  margin-top: 1em; }
  .socialicons:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
  .socialicons .socialicons__list {
    position: relative;
    margin-bottom: 0;
    height: 3.25rem; }
    .socialicons .socialicons__list li {
      margin: 0 0.25em;
      display: inline-block; }
  .socialicons .socialicons__list .sociallink {
    display: block;
    height: 3.25rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    background-color: #ffffff;
    line-height: calc(3.25rem - 2px);
    margin: 0;
    border-radius: 2rem; }
    .color-white .socialicons .socialicons__list .sociallink {
      border-color: rgba(255, 255, 255, 0.15); }
    .bg-black .socialicons .socialicons__list .sociallink {
      background-color: #000000; }
    .socialicons .socialicons__list .sociallink:after {
      content: "";
      height: 0;
      visibility: hidden;
      clear: both;
      display: block; }
    .socialicons .socialicons__list .sociallink .sociallink__text {
      overflow: hidden;
      font-size: 12px;
      display: block;
      float: left;
      text-align: right;
      width: 0;
      -webkit-transition: all 150ms cubic-bezier(0.65, 0, 0.35, 1) 0s;
      transition: all 150ms cubic-bezier(0.65, 0, 0.35, 1) 0s;
      text-align: left; }
    .socialicons .socialicons__list .sociallink .sociallink__icon {
      width: 3.25rem;
      text-align: center;
      display: block;
      float: left;
      padding: 0;
      border: none; }
      .socialicons .socialicons__list .sociallink .sociallink__icon .fab,
      .socialicons .socialicons__list .sociallink .sociallink__icon .far,
      .socialicons .socialicons__list .sociallink .sociallink__icon .fal,
      .socialicons .socialicons__list .sociallink .sociallink__icon .fas {
        display: inline-block;
        padding: 0; }
    @media only screen and (min-width: 700px) {
      .socialicons .socialicons__list .sociallink a:hover .sociallink .fab {
        display: inline-block;
        padding: 0; }
      .socialicons .socialicons__list .sociallink:hover .sociallink__text {
        width: 65px; } }

/*================= LAYOUT =================*/
/* ==========================================================================
   Grid -- layout/_grid.scss
   ========================================================================== */
/* ==========================================================================
  General flex box classes -- layout/_grid.scss
  ========================================================================== */
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */ }

/* ==========================================================================
  Rows -- layout/_grid.scss
  ========================================================================== */
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -0.5em;
  margin-right: -0.5em; }
  @media only screen and (min-width: 550px) {
    .row {
      margin-left: -0.5em;
      margin-right: -0.5em; } }
  @media only screen and (min-width: 700px) {
    .row {
      margin-left: -0.75em;
      margin-right: -0.75em; } }
  @media only screen and (min-width: 1000px) {
    .row {
      margin-left: -0.75em;
      margin-right: -0.75em; } }
  @media only screen and (min-width: 1200px) {
    .row {
      margin-left: -0.75em;
      margin-right: -0.75em; } }
  @media only screen and (min-width: 1400px) {
    .row {
      margin-left: -0.75em;
      margin-right: -0.75em; } }
  @media only screen and (min-width: 1600px) {
    .row {
      margin-left: -0.75em;
      margin-right: -0.75em; } }

.row--nowrap {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
  @media only screen and (min-width: 550px) {
    .row--nowrap--sm {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; } }
  @media only screen and (min-width: 700px) {
    .row--nowrap--md {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; } }
  @media only screen and (min-width: 1000px) {
    .row--nowrap--lg {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; } }
  @media only screen and (min-width: 1200px) {
    .row--nowrap--xl {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; } }
  @media only screen and (min-width: 1400px) {
    .row--nowrap--ws {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; } }
  @media only screen and (min-width: 1600px) {
    .row--nowrap--xw {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; } }
  @media only screen and (min-width: 1800px) {
    .row--nowrap--xxw {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; } }
  @media only screen and (min-width: 2000px) {
    .row--nowrap--xxxw {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; } }

.row--wrapreverse {
  -webkit-flex-wrap: wrap-reverse;
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse; }
  @media only screen and (min-width: 550px) {
    .row--wrapreverse--sm {
      -webkit-flex-wrap: wrap-reverse;
          -ms-flex-wrap: wrap-reverse;
              flex-wrap: wrap-reverse; } }
  @media only screen and (min-width: 700px) {
    .row--wrapreverse--md {
      -webkit-flex-wrap: wrap-reverse;
          -ms-flex-wrap: wrap-reverse;
              flex-wrap: wrap-reverse; } }
  @media only screen and (min-width: 1000px) {
    .row--wrapreverse--lg {
      -webkit-flex-wrap: wrap-reverse;
          -ms-flex-wrap: wrap-reverse;
              flex-wrap: wrap-reverse; } }
  @media only screen and (min-width: 1200px) {
    .row--wrapreverse--xl {
      -webkit-flex-wrap: wrap-reverse;
          -ms-flex-wrap: wrap-reverse;
              flex-wrap: wrap-reverse; } }
  @media only screen and (min-width: 1400px) {
    .row--wrapreverse--ws {
      -webkit-flex-wrap: wrap-reverse;
          -ms-flex-wrap: wrap-reverse;
              flex-wrap: wrap-reverse; } }
  @media only screen and (min-width: 1600px) {
    .row--wrapreverse--xw {
      -webkit-flex-wrap: wrap-reverse;
          -ms-flex-wrap: wrap-reverse;
              flex-wrap: wrap-reverse; } }
  @media only screen and (min-width: 1800px) {
    .row--wrapreverse--xxw {
      -webkit-flex-wrap: wrap-reverse;
          -ms-flex-wrap: wrap-reverse;
              flex-wrap: wrap-reverse; } }
  @media only screen and (min-width: 2000px) {
    .row--wrapreverse--xxxw {
      -webkit-flex-wrap: wrap-reverse;
          -ms-flex-wrap: wrap-reverse;
              flex-wrap: wrap-reverse; } }

.row--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media only screen and (min-width: 550px) {
    .row--column--sm {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media only screen and (min-width: 700px) {
    .row--column--md {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media only screen and (min-width: 1000px) {
    .row--column--lg {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media only screen and (min-width: 1200px) {
    .row--column--xl {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media only screen and (min-width: 1400px) {
    .row--column--ws {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media only screen and (min-width: 1600px) {
    .row--column--xw {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media only screen and (min-width: 1800px) {
    .row--column--xxw {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media only screen and (min-width: 2000px) {
    .row--column--xxxw {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }

.row--columnreverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse; }
  @media only screen and (min-width: 550px) {
    .row--columnreverse--sm {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  @media only screen and (min-width: 700px) {
    .row--columnreverse--md {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  @media only screen and (min-width: 1000px) {
    .row--columnreverse--lg {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  @media only screen and (min-width: 1200px) {
    .row--columnreverse--xl {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  @media only screen and (min-width: 1400px) {
    .row--columnreverse--ws {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  @media only screen and (min-width: 1600px) {
    .row--columnreverse--xw {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  @media only screen and (min-width: 1800px) {
    .row--columnreverse--xxw {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  @media only screen and (min-width: 2000px) {
    .row--columnreverse--xxxw {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }

.row--row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row; }
  @media only screen and (min-width: 550px) {
    .row--row--sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; } }
  @media only screen and (min-width: 700px) {
    .row--row--md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; } }
  @media only screen and (min-width: 1000px) {
    .row--row--lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; } }
  @media only screen and (min-width: 1200px) {
    .row--row--xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; } }
  @media only screen and (min-width: 1400px) {
    .row--row--ws {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; } }
  @media only screen and (min-width: 1600px) {
    .row--row--xw {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; } }
  @media only screen and (min-width: 1800px) {
    .row--row--xxw {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; } }
  @media only screen and (min-width: 2000px) {
    .row--row--xxxw {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; } }

.row--rowreverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  @media only screen and (min-width: 550px) {
    .row--rowreverse--sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }
  @media only screen and (min-width: 700px) {
    .row--rowreverse--md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }
  @media only screen and (min-width: 1000px) {
    .row--rowreverse--lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }
  @media only screen and (min-width: 1200px) {
    .row--rowreverse--xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }
  @media only screen and (min-width: 1400px) {
    .row--rowreverse--ws {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }
  @media only screen and (min-width: 1600px) {
    .row--rowreverse--xw {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }
  @media only screen and (min-width: 1800px) {
    .row--rowreverse--xxw {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }
  @media only screen and (min-width: 2000px) {
    .row--rowreverse--xxxw {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }

.row--mb {
  margin-bottom: calc(0.5em * -3); }
  @media only screen and (min-width: 550px) {
    .row--mb {
      margin-bottom: calc(0.5em * -3); } }
  @media only screen and (min-width: 700px) {
    .row--mb {
      margin-bottom: calc(0.75em * -3); } }
  @media only screen and (min-width: 1000px) {
    .row--mb {
      margin-bottom: calc(0.75em * -3); } }
  @media only screen and (min-width: 1200px) {
    .row--mb {
      margin-bottom: calc(0.75em * -3); } }
  @media only screen and (min-width: 1400px) {
    .row--mb {
      margin-bottom: calc(0.75em * -3); } }
  @media only screen and (min-width: 1600px) {
    .row--mb {
      margin-bottom: calc(0.75em * -3); } }
  .row--mb > .col {
    margin-bottom: calc(0.5em * 3); }
    @media only screen and (min-width: 550px) {
      .row--mb > .col {
        margin-bottom: calc(0.5em * 3); } }
    @media only screen and (min-width: 700px) {
      .row--mb > .col {
        margin-bottom: calc(0.75em * 3); } }
    @media only screen and (min-width: 1000px) {
      .row--mb > .col {
        margin-bottom: calc(0.75em * 3); } }
    @media only screen and (min-width: 1200px) {
      .row--mb > .col {
        margin-bottom: calc(0.75em * 3); } }
    @media only screen and (min-width: 1400px) {
      .row--mb > .col {
        margin-bottom: calc(0.75em * 3); } }
    @media only screen and (min-width: 1600px) {
      .row--mb > .col {
        margin-bottom: calc(0.75em * 3); } }

/* ==========================================================================
  Alignments -- layout/_grid.scss
  ========================================================================== */
.justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media only screen and (min-width: 550px) {
    .justify-center--sm {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media only screen and (min-width: 700px) {
    .justify-center--md {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media only screen and (min-width: 1000px) {
    .justify-center--lg {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media only screen and (min-width: 1200px) {
    .justify-center--xl {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media only screen and (min-width: 1400px) {
    .justify-center--ws {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media only screen and (min-width: 1600px) {
    .justify-center--xw {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media only screen and (min-width: 1800px) {
    .justify-center--xxw {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media only screen and (min-width: 2000px) {
    .justify-center--xxxw {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }

.justify-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  @media only screen and (min-width: 550px) {
    .justify-start--sm {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  @media only screen and (min-width: 700px) {
    .justify-start--md {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  @media only screen and (min-width: 1000px) {
    .justify-start--lg {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  @media only screen and (min-width: 1200px) {
    .justify-start--xl {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  @media only screen and (min-width: 1400px) {
    .justify-start--ws {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  @media only screen and (min-width: 1600px) {
    .justify-start--xw {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  @media only screen and (min-width: 1800px) {
    .justify-start--xxw {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  @media only screen and (min-width: 2000px) {
    .justify-start--xxxw {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }

.justify-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media only screen and (min-width: 550px) {
    .justify-end--sm {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }
  @media only screen and (min-width: 700px) {
    .justify-end--md {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }
  @media only screen and (min-width: 1000px) {
    .justify-end--lg {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }
  @media only screen and (min-width: 1200px) {
    .justify-end--xl {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }
  @media only screen and (min-width: 1400px) {
    .justify-end--ws {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }
  @media only screen and (min-width: 1600px) {
    .justify-end--xw {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }
  @media only screen and (min-width: 1800px) {
    .justify-end--xxw {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }
  @media only screen and (min-width: 2000px) {
    .justify-end--xxxw {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }

.justify-spacebetween {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media only screen and (min-width: 550px) {
    .justify-spacebetween--sm {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  @media only screen and (min-width: 700px) {
    .justify-spacebetween--md {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  @media only screen and (min-width: 1000px) {
    .justify-spacebetween--lg {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  @media only screen and (min-width: 1200px) {
    .justify-spacebetween--xl {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  @media only screen and (min-width: 1400px) {
    .justify-spacebetween--ws {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  @media only screen and (min-width: 1600px) {
    .justify-spacebetween--xw {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  @media only screen and (min-width: 1800px) {
    .justify-spacebetween--xxw {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  @media only screen and (min-width: 2000px) {
    .justify-spacebetween--xxxw {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; } }

.justify-spacearound {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around; }
  @media only screen and (min-width: 550px) {
    .justify-spacearound--sm {
      -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
              justify-content: space-around; } }
  @media only screen and (min-width: 700px) {
    .justify-spacearound--md {
      -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
              justify-content: space-around; } }
  @media only screen and (min-width: 1000px) {
    .justify-spacearound--lg {
      -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
              justify-content: space-around; } }
  @media only screen and (min-width: 1200px) {
    .justify-spacearound--xl {
      -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
              justify-content: space-around; } }
  @media only screen and (min-width: 1400px) {
    .justify-spacearound--ws {
      -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
              justify-content: space-around; } }
  @media only screen and (min-width: 1600px) {
    .justify-spacearound--xw {
      -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
              justify-content: space-around; } }
  @media only screen and (min-width: 1800px) {
    .justify-spacearound--xxw {
      -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
              justify-content: space-around; } }
  @media only screen and (min-width: 2000px) {
    .justify-spacearound--xxxw {
      -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
              justify-content: space-around; } }

.justify-spaceevenly {
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }
  @media only screen and (min-width: 550px) {
    .justify-spaceevenly--sm {
      -webkit-box-pack: space-evenly;
      -webkit-justify-content: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }
  @media only screen and (min-width: 700px) {
    .justify-spaceevenly--md {
      -webkit-box-pack: space-evenly;
      -webkit-justify-content: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }
  @media only screen and (min-width: 1000px) {
    .justify-spaceevenly--lg {
      -webkit-box-pack: space-evenly;
      -webkit-justify-content: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }
  @media only screen and (min-width: 1200px) {
    .justify-spaceevenly--xl {
      -webkit-box-pack: space-evenly;
      -webkit-justify-content: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }
  @media only screen and (min-width: 1400px) {
    .justify-spaceevenly--ws {
      -webkit-box-pack: space-evenly;
      -webkit-justify-content: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }
  @media only screen and (min-width: 1600px) {
    .justify-spaceevenly--xw {
      -webkit-box-pack: space-evenly;
      -webkit-justify-content: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }
  @media only screen and (min-width: 1800px) {
    .justify-spaceevenly--xxw {
      -webkit-box-pack: space-evenly;
      -webkit-justify-content: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }
  @media only screen and (min-width: 2000px) {
    .justify-spaceevenly--xxxw {
      -webkit-box-pack: space-evenly;
      -webkit-justify-content: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }

.align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  @media only screen and (min-width: 550px) {
    .align-center--sm {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; } }
  @media only screen and (min-width: 700px) {
    .align-center--md {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; } }
  @media only screen and (min-width: 1000px) {
    .align-center--lg {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; } }
  @media only screen and (min-width: 1200px) {
    .align-center--xl {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; } }
  @media only screen and (min-width: 1400px) {
    .align-center--ws {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; } }
  @media only screen and (min-width: 1600px) {
    .align-center--xw {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; } }
  @media only screen and (min-width: 1800px) {
    .align-center--xxw {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; } }
  @media only screen and (min-width: 2000px) {
    .align-center--xxxw {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; } }

.align-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media only screen and (min-width: 550px) {
    .align-start--sm {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }
  @media only screen and (min-width: 700px) {
    .align-start--md {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }
  @media only screen and (min-width: 1000px) {
    .align-start--lg {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }
  @media only screen and (min-width: 1200px) {
    .align-start--xl {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }
  @media only screen and (min-width: 1400px) {
    .align-start--ws {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }
  @media only screen and (min-width: 1600px) {
    .align-start--xw {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }
  @media only screen and (min-width: 1800px) {
    .align-start--xxw {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }
  @media only screen and (min-width: 2000px) {
    .align-start--xxxw {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }

.align-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end; }
  @media only screen and (min-width: 550px) {
    .align-end--sm {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; } }
  @media only screen and (min-width: 700px) {
    .align-end--md {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; } }
  @media only screen and (min-width: 1000px) {
    .align-end--lg {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; } }
  @media only screen and (min-width: 1200px) {
    .align-end--xl {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; } }
  @media only screen and (min-width: 1400px) {
    .align-end--ws {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; } }
  @media only screen and (min-width: 1600px) {
    .align-end--xw {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; } }
  @media only screen and (min-width: 1800px) {
    .align-end--xxw {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; } }
  @media only screen and (min-width: 2000px) {
    .align-end--xxxw {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; } }

/* ==========================================================================
  Columns -- layout/_grid.scss
  ========================================================================== */
.col {
  vertical-align: top;
  box-sizing: border-box;
  padding-left: 0.5em;
  padding-right: 0.5em; }
  @media only screen and (min-width: 550px) {
    .col {
      padding-left: 0.5em;
      padding-right: 0.5em; } }
  @media only screen and (min-width: 700px) {
    .col {
      padding-left: 0.75em;
      padding-right: 0.75em; } }
  @media only screen and (min-width: 1000px) {
    .col {
      padding-left: 0.75em;
      padding-right: 0.75em; } }
  @media only screen and (min-width: 1200px) {
    .col {
      padding-left: 0.75em;
      padding-right: 0.75em; } }
  @media only screen and (min-width: 1400px) {
    .col {
      padding-left: 0.75em;
      padding-right: 0.75em; } }
  @media only screen and (min-width: 1600px) {
    .col {
      padding-left: 0.75em;
      padding-right: 0.75em; } }

.col--content {
  width: 100%; }
  @media only screen and (min-width: 550px) {
    .col--content {
      width: 83.33%; } }
  @media only screen and (min-width: 700px) {
    .col--content {
      width: 66.66666%; } }
  @media only screen and (min-width: 1000px) {
    .col--content {
      width: 60%; } }
  @media only screen and (min-width: 1200px) {
    .col--content {
      width: 50%; } }
  .col--content--wide {
    width: 100%; }
    @media only screen and (min-width: 700px) {
      .col--content--wide {
        width: 83.33%; } }
    @media only screen and (min-width: 1000px) {
      .col--content--wide {
        width: 80%; } }
    @media only screen and (min-width: 1200px) {
      .col--content--wide {
        width: 66.66666%; } }

.col--nopadleft {
  padding-left: 0; }
  @media only screen and (min-width: 550px) {
    .col--nopadleft--sm {
      padding-left: 0; } }
  @media only screen and (min-width: 700px) {
    .col--nopadleft--md {
      padding-left: 0; } }
  @media only screen and (min-width: 1000px) {
    .col--nopadleft--lg {
      padding-left: 0; } }
  @media only screen and (min-width: 1200px) {
    .col--nopadleft--xl {
      padding-left: 0; } }
  @media only screen and (min-width: 1400px) {
    .col--nopadleft--ws {
      padding-left: 0; } }
  @media only screen and (min-width: 1600px) {
    .col--nopadleft--xw {
      padding-left: 0; } }
  @media only screen and (min-width: 1800px) {
    .col--nopadleft--xxw {
      padding-left: 0; } }
  @media only screen and (min-width: 2000px) {
    .col--nopadleft--xxxw {
      padding-left: 0; } }

.col--nopadright {
  padding-right: 0; }
  @media only screen and (min-width: 550px) {
    .col--nopadright--sm {
      padding-right: 0; } }
  @media only screen and (min-width: 700px) {
    .col--nopadright--md {
      padding-right: 0; } }
  @media only screen and (min-width: 1000px) {
    .col--nopadright--lg {
      padding-right: 0; } }
  @media only screen and (min-width: 1200px) {
    .col--nopadright--xl {
      padding-right: 0; } }
  @media only screen and (min-width: 1400px) {
    .col--nopadright--ws {
      padding-right: 0; } }
  @media only screen and (min-width: 1600px) {
    .col--nopadright--xw {
      padding-right: 0; } }
  @media only screen and (min-width: 1800px) {
    .col--nopadright--xxw {
      padding-right: 0; } }
  @media only screen and (min-width: 2000px) {
    .col--nopadright--xxxw {
      padding-right: 0; } }

/* ==========================================================================
  Grid classes -- layout/_grid.scss
  ========================================================================== */
.col-auto {
  width: auto; }

.col-1 {
  width: 100%; }

.col-1-2 {
  width: 50%; }

.col-1-3 {
  width: 33.3%; }

.col-2-3 {
  width: 66.66666%; }

.col-1-6 {
  width: 16.66666%; }

.col-1-4 {
  width: 25%; }

.col-3-4 {
  width: 75%; }

.col-1-5 {
  width: 20%; }

.col-2-5 {
  width: 40%; }

.col-3-5 {
  width: 60%; }

.col-4-5 {
  width: 80%; }

.col-1-6 {
  width: 16.656%; }

.col-5-6 {
  width: 83.33%; }

.col-1-8 {
  width: 12.5%; }

.col-3-8 {
  width: 37.5%; }

.col-5-8 {
  width: 62.5%; }

.col-7-8 {
  width: 87.5%; }

.col-1-12 {
  width: 8.3333%; }

.col-5-12 {
  width: 41.6666%; }

.col-7-12 {
  width: 58.3333%; }

.col-11-12 {
  width: 91.6666%; }

.col-1-24 {
  width: 4.1666%; }

.col-5-24 {
  width: 20.8333%; }

.col-7-24 {
  width: 29.1666%; }

.col-11-24 {
  width: 45.8333%; }

.col-13-24 {
  width: 54.1666%; }

.col-17-24 {
  width: 70.8333%; }

.col-19-24 {
  width: 79.1666%; }

.col-23-24 {
  width: 95.8333%; }

@media only screen and (min-width: 550px) {
  .col-sm-auto {
    width: auto; }

  .col-sm-1 {
    width: 100%; }

  .col-sm-1-2 {
    width: 50%; }

  .col-sm-1-3 {
    width: 33.3%; }

  .col-sm-2-3 {
    width: 66.66666%; }

  .col-sm-1-6 {
    width: 16.66666%; }

  .col-sm-1-4 {
    width: 25%; }

  .col-sm-3-4 {
    width: 75%; }

  .col-sm-1-5 {
    width: 20%; }

  .col-sm-2-5 {
    width: 40%; }

  .col-sm-3-5 {
    width: 60%; }

  .col-sm-4-5 {
    width: 80%; }

  .col-sm-1-6 {
    width: 16.656%; }

  .col-sm-5-6 {
    width: 83.33%; }

  .col-sm-1-8 {
    width: 12.5%; }

  .col-sm-3-8 {
    width: 37.5%; }

  .col-sm-5-8 {
    width: 62.5%; }

  .col-sm-7-8 {
    width: 87.5%; }

  .col-sm-1-12 {
    width: 8.3333%; }

  .col-sm-5-12 {
    width: 41.6666%; }

  .col-sm-7-12 {
    width: 58.3333%; }

  .col-sm-11-12 {
    width: 91.6666%; }

  .col-sm-1-24 {
    width: 4.1666%; }

  .col-sm-5-24 {
    width: 20.8333%; }

  .col-sm-7-24 {
    width: 29.1666%; }

  .col-sm-11-24 {
    width: 45.8333%; }

  .col-sm-13-24 {
    width: 54.1666%; }

  .col-sm-17-24 {
    width: 70.8333%; }

  .col-sm-19-24 {
    width: 79.1666%; }

  .col-sm-23-24 {
    width: 95.8333%; } }
@media only screen and (min-width: 700px) {
  .col-md-auto {
    width: auto; }

  .col-md-1 {
    width: 100%; }

  .col-md-1-2 {
    width: 50%; }

  .col-md-1-3 {
    width: 33.3%; }

  .col-md-2-3 {
    width: 66.66666%; }

  .col-md-1-6 {
    width: 16.66666%; }

  .col-md-1-4 {
    width: 25%; }

  .col-md-3-4 {
    width: 75%; }

  .col-md-1-5 {
    width: 20%; }

  .col-md-2-5 {
    width: 40%; }

  .col-md-3-5 {
    width: 60%; }

  .col-md-4-5 {
    width: 80%; }

  .col-md-1-6 {
    width: 16.656%; }

  .col-md-5-6 {
    width: 83.33%; }

  .col-md-1-8 {
    width: 12.5%; }

  .col-md-3-8 {
    width: 37.5%; }

  .col-md-5-8 {
    width: 62.5%; }

  .col-md-7-8 {
    width: 87.5%; }

  .col-md-1-12 {
    width: 8.3333%; }

  .col-md-5-12 {
    width: 41.6666%; }

  .col-md-7-12 {
    width: 58.3333%; }

  .col-md-11-12 {
    width: 91.6666%; }

  .col-md-1-24 {
    width: 4.1666%; }

  .col-md-5-24 {
    width: 20.8333%; }

  .col-md-7-24 {
    width: 29.1666%; }

  .col-md-11-24 {
    width: 45.8333%; }

  .col-md-13-24 {
    width: 54.1666%; }

  .col-md-17-24 {
    width: 70.8333%; }

  .col-md-19-24 {
    width: 79.1666%; }

  .col-md-23-24 {
    width: 95.8333%; } }
@media only screen and (min-width: 1000px) {
  .col-lg-auto {
    width: auto; }

  .col-lg-1 {
    width: 100%; }

  .col-lg-1-2 {
    width: 50%; }

  .col-lg-1-3 {
    width: 33.3%; }

  .col-lg-2-3 {
    width: 66.66666%; }

  .col-lg-1-6 {
    width: 16.66666%; }

  .col-lg-1-4 {
    width: 25%; }

  .col-lg-3-4 {
    width: 75%; }

  .col-lg-1-5 {
    width: 20%; }

  .col-lg-2-5 {
    width: 40%; }

  .col-lg-3-5 {
    width: 60%; }

  .col-lg-4-5 {
    width: 80%; }

  .col-lg-1-6 {
    width: 16.656%; }

  .col-lg-5-6 {
    width: 83.33%; }

  .col-lg-1-8 {
    width: 12.5%; }

  .col-lg-3-8 {
    width: 37.5%; }

  .col-lg-5-8 {
    width: 62.5%; }

  .col-lg-7-8 {
    width: 87.5%; }

  .col-lg-1-12 {
    width: 8.3333%; }

  .col-lg-5-12 {
    width: 41.6666%; }

  .col-lg-7-12 {
    width: 58.3333%; }

  .col-lg-11-12 {
    width: 91.6666%; }

  .col-lg-1-24 {
    width: 4.1666%; }

  .col-lg-5-24 {
    width: 20.8333%; }

  .col-lg-7-24 {
    width: 29.1666%; }

  .col-lg-11-24 {
    width: 45.8333%; }

  .col-lg-13-24 {
    width: 54.1666%; }

  .col-lg-17-24 {
    width: 70.8333%; }

  .col-lg-19-24 {
    width: 79.1666%; }

  .col-lg-23-24 {
    width: 95.8333%; } }
@media only screen and (min-width: 1200px) {
  .col-xl-auto {
    width: auto; }

  .col-xl-1 {
    width: 100%; }

  .col-xl-1-2 {
    width: 50%; }

  .col-xl-1-3 {
    width: 33.3%; }

  .col-xl-2-3 {
    width: 66.66666%; }

  .col-xl-1-6 {
    width: 16.66666%; }

  .col-xl-1-4 {
    width: 25%; }

  .col-xl-3-4 {
    width: 75%; }

  .col-xl-1-5 {
    width: 20%; }

  .col-xl-2-5 {
    width: 40%; }

  .col-xl-3-5 {
    width: 60%; }

  .col-xl-4-5 {
    width: 80%; }

  .col-xl-1-6 {
    width: 16.656%; }

  .col-xl-5-6 {
    width: 83.33%; }

  .col-xl-1-8 {
    width: 12.5%; }

  .col-xl-3-8 {
    width: 37.5%; }

  .col-xl-5-8 {
    width: 62.5%; }

  .col-xl-7-8 {
    width: 87.5%; }

  .col-xl-1-12 {
    width: 8.3333%; }

  .col-xl-5-12 {
    width: 41.6666%; }

  .col-xl-7-12 {
    width: 58.3333%; }

  .col-xl-11-12 {
    width: 91.6666%; }

  .col-xl-1-24 {
    width: 4.1666%; }

  .col-xl-5-24 {
    width: 20.8333%; }

  .col-xl-7-24 {
    width: 29.1666%; }

  .col-xl-11-24 {
    width: 45.8333%; }

  .col-xl-13-24 {
    width: 54.1666%; }

  .col-xl-17-24 {
    width: 70.8333%; }

  .col-xl-19-24 {
    width: 79.1666%; }

  .col-xl-23-24 {
    width: 95.8333%; } }
@media only screen and (min-width: 1400px) {
  .col-ws-auto {
    width: auto; }

  .col-ws-1 {
    width: 100%; }

  .col-ws-1-2 {
    width: 50%; }

  .col-ws-1-3 {
    width: 33.3%; }

  .col-ws-2-3 {
    width: 66.66666%; }

  .col-ws-1-6 {
    width: 16.66666%; }

  .col-ws-1-4 {
    width: 25%; }

  .col-ws-3-4 {
    width: 75%; }

  .col-ws-1-5 {
    width: 20%; }

  .col-ws-2-5 {
    width: 40%; }

  .col-ws-3-5 {
    width: 60%; }

  .col-ws-4-5 {
    width: 80%; }

  .col-ws-1-6 {
    width: 16.656%; }

  .col-ws-5-6 {
    width: 83.33%; }

  .col-ws-1-8 {
    width: 12.5%; }

  .col-ws-3-8 {
    width: 37.5%; }

  .col-ws-5-8 {
    width: 62.5%; }

  .col-ws-7-8 {
    width: 87.5%; }

  .col-ws-1-12 {
    width: 8.3333%; }

  .col-ws-5-12 {
    width: 41.6666%; }

  .col-ws-7-12 {
    width: 58.3333%; }

  .col-ws-11-12 {
    width: 91.6666%; }

  .col-ws-1-24 {
    width: 4.1666%; }

  .col-ws-5-24 {
    width: 20.8333%; }

  .col-ws-7-24 {
    width: 29.1666%; }

  .col-ws-11-24 {
    width: 45.8333%; }

  .col-ws-13-24 {
    width: 54.1666%; }

  .col-ws-17-24 {
    width: 70.8333%; }

  .col-ws-19-24 {
    width: 79.1666%; }

  .col-ws-23-24 {
    width: 95.8333%; } }
@media only screen and (min-width: 1600px) {
  .col-xw-auto {
    width: auto; }

  .col-xw-1 {
    width: 100%; }

  .col-xw-1-2 {
    width: 50%; }

  .col-xw-1-3 {
    width: 33.3%; }

  .col-xw-2-3 {
    width: 66.66666%; }

  .col-xw-1-6 {
    width: 16.66666%; }

  .col-xw-1-4 {
    width: 25%; }

  .col-xw-3-4 {
    width: 75%; }

  .col-xw-1-5 {
    width: 20%; }

  .col-xw-2-5 {
    width: 40%; }

  .col-xw-3-5 {
    width: 60%; }

  .col-xw-4-5 {
    width: 80%; }

  .col-xw-1-6 {
    width: 16.656%; }

  .col-xw-5-6 {
    width: 83.33%; }

  .col-xw-1-8 {
    width: 12.5%; }

  .col-xw-3-8 {
    width: 37.5%; }

  .col-xw-5-8 {
    width: 62.5%; }

  .col-xw-7-8 {
    width: 87.5%; }

  .col-xw-1-12 {
    width: 8.3333%; }

  .col-xw-5-12 {
    width: 41.6666%; }

  .col-xw-7-12 {
    width: 58.3333%; }

  .col-xw-11-12 {
    width: 91.6666%; }

  .col-xw-1-24 {
    width: 4.1666%; }

  .col-xw-5-24 {
    width: 20.8333%; }

  .col-xw-7-24 {
    width: 29.1666%; }

  .col-xw-11-24 {
    width: 45.8333%; }

  .col-xw-13-24 {
    width: 54.1666%; }

  .col-xw-17-24 {
    width: 70.8333%; }

  .col-xw-19-24 {
    width: 79.1666%; }

  .col-xw-23-24 {
    width: 95.8333%; } }
@media only screen and (min-width: 1800px) {
  .col-xxw-auto {
    width: auto; }

  .col-xxw-1 {
    width: 100%; }

  .col-xxw-1-2 {
    width: 50%; }

  .col-xxw-1-3 {
    width: 33.3%; }

  .col-xxw-2-3 {
    width: 66.66666%; }

  .col-xxw-1-6 {
    width: 16.66666%; }

  .col-xxw-1-4 {
    width: 25%; }

  .col-xxw-3-4 {
    width: 75%; }

  .col-xxw-1-5 {
    width: 20%; }

  .col-xxw-2-5 {
    width: 40%; }

  .col-xxw-3-5 {
    width: 60%; }

  .col-xxw-4-5 {
    width: 80%; }

  .col-xxw-1-6 {
    width: 16.656%; }

  .col-xxw-5-6 {
    width: 83.33%; }

  .col-xxw-1-8 {
    width: 12.5%; }

  .col-xxw-3-8 {
    width: 37.5%; }

  .col-xxw-5-8 {
    width: 62.5%; }

  .col-xxw-7-8 {
    width: 87.5%; }

  .col-xxw-1-12 {
    width: 8.3333%; }

  .col-xxw-5-12 {
    width: 41.6666%; }

  .col-xxw-7-12 {
    width: 58.3333%; }

  .col-xxw-11-12 {
    width: 91.6666%; }

  .col-xxw-1-24 {
    width: 4.1666%; }

  .col-xxw-5-24 {
    width: 20.8333%; }

  .col-xxw-7-24 {
    width: 29.1666%; }

  .col-xxw-11-24 {
    width: 45.8333%; }

  .col-xxw-13-24 {
    width: 54.1666%; }

  .col-xxw-17-24 {
    width: 70.8333%; }

  .col-xxw-19-24 {
    width: 79.1666%; }

  .col-xxw-23-24 {
    width: 95.8333%; } }
@media only screen and (min-width: 2000px) {
  .col-xxxw-auto {
    width: auto; }

  .col-xxxw-1 {
    width: 100%; }

  .col-xxxw-1-2 {
    width: 50%; }

  .col-xxxw-1-3 {
    width: 33.3%; }

  .col-xxxw-2-3 {
    width: 66.66666%; }

  .col-xxxw-1-6 {
    width: 16.66666%; }

  .col-xxxw-1-4 {
    width: 25%; }

  .col-xxxw-3-4 {
    width: 75%; }

  .col-xxxw-1-5 {
    width: 20%; }

  .col-xxxw-2-5 {
    width: 40%; }

  .col-xxxw-3-5 {
    width: 60%; }

  .col-xxxw-4-5 {
    width: 80%; }

  .col-xxxw-1-6 {
    width: 16.656%; }

  .col-xxxw-5-6 {
    width: 83.33%; }

  .col-xxxw-1-8 {
    width: 12.5%; }

  .col-xxxw-3-8 {
    width: 37.5%; }

  .col-xxxw-5-8 {
    width: 62.5%; }

  .col-xxxw-7-8 {
    width: 87.5%; }

  .col-xxxw-1-12 {
    width: 8.3333%; }

  .col-xxxw-5-12 {
    width: 41.6666%; }

  .col-xxxw-7-12 {
    width: 58.3333%; }

  .col-xxxw-11-12 {
    width: 91.6666%; }

  .col-xxxw-1-24 {
    width: 4.1666%; }

  .col-xxxw-5-24 {
    width: 20.8333%; }

  .col-xxxw-7-24 {
    width: 29.1666%; }

  .col-xxxw-11-24 {
    width: 45.8333%; }

  .col-xxxw-13-24 {
    width: 54.1666%; }

  .col-xxxw-17-24 {
    width: 70.8333%; }

  .col-xxxw-19-24 {
    width: 79.1666%; }

  .col-xxxw-23-24 {
    width: 95.8333%; } }
/* ==========================================================================
   Forms -- layout/_forms.scss
   ========================================================================== */
/* ==========================================================================
  General Form Styling -- layout/_forms.scss
  ========================================================================== */
input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="search"], input[type="tel"], select, textarea {
  padding: 1em;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.25em;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: #000000; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus,
  input[type="number"]:focus,
  input[type="search"]:focus,
  input[type="tel"]:focus,
  select:focus,
  textarea:focus {
    outline: none;
    border: solid 1px #d9a00e !important; }
  input[type="text"].placeholder, input[type="email"].placeholder, input[type="password"].placeholder, input[type="number"].placeholder, input[type="search"].placeholder, input[type="tel"].placeholder, select.placeholder, textarea.placeholder {
    color: rgba(0, 0, 0, 0.5); }
    .color-white input[type="text"].placeholder,
    .color-white input[type="email"].placeholder,
    .color-white input[type="password"].placeholder,
    .color-white input[type="number"].placeholder,
    .color-white input[type="search"].placeholder,
    .color-white input[type="tel"].placeholder,
    .color-white select.placeholder,
    .color-white textarea.placeholder {
      color: rgba(255, 255, 255, 0.5); }
  input[type="text"]:-moz-placeholder, input[type="email"]:-moz-placeholder, input[type="password"]:-moz-placeholder, input[type="number"]:-moz-placeholder, input[type="search"]:-moz-placeholder, input[type="tel"]:-moz-placeholder, select:-moz-placeholder, textarea:-moz-placeholder {
    color: rgba(0, 0, 0, 0.5); }
    .color-white input[type="text"]:-moz-placeholder,
    .color-white input[type="email"]:-moz-placeholder,
    .color-white input[type="password"]:-moz-placeholder,
    .color-white input[type="number"]:-moz-placeholder,
    .color-white input[type="search"]:-moz-placeholder,
    .color-white input[type="tel"]:-moz-placeholder,
    .color-white select:-moz-placeholder,
    .color-white textarea:-moz-placeholder {
      color: rgba(255, 255, 255, 0.5); }
  input[type="text"]::-moz-placeholder, input[type="email"]::-moz-placeholder, input[type="password"]::-moz-placeholder, input[type="number"]::-moz-placeholder, input[type="search"]::-moz-placeholder, input[type="tel"]::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder {
    color: rgba(0, 0, 0, 0.5); }
    .color-white input[type="text"]::-moz-placeholder,
    .color-white input[type="email"]::-moz-placeholder,
    .color-white input[type="password"]::-moz-placeholder,
    .color-white input[type="number"]::-moz-placeholder,
    .color-white input[type="search"]::-moz-placeholder,
    .color-white input[type="tel"]::-moz-placeholder,
    .color-white select::-moz-placeholder,
    .color-white textarea::-moz-placeholder {
      color: rgba(255, 255, 255, 0.5); }
  input[type="text"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="search"]:-ms-input-placeholder, input[type="tel"]:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
    .color-white input[type="text"]:-ms-input-placeholder,
    .color-white input[type="email"]:-ms-input-placeholder,
    .color-white input[type="password"]:-ms-input-placeholder,
    .color-white input[type="number"]:-ms-input-placeholder,
    .color-white input[type="search"]:-ms-input-placeholder,
    .color-white input[type="tel"]:-ms-input-placeholder,
    .color-white select:-ms-input-placeholder,
    .color-white textarea:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.5); }
  input[type="text"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="search"]::-webkit-input-placeholder, input[type="tel"]::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5); }
    .color-white input[type="text"]::-webkit-input-placeholder,
    .color-white input[type="email"]::-webkit-input-placeholder,
    .color-white input[type="password"]::-webkit-input-placeholder,
    .color-white input[type="number"]::-webkit-input-placeholder,
    .color-white input[type="search"]::-webkit-input-placeholder,
    .color-white input[type="tel"]::-webkit-input-placeholder,
    .color-white select::-webkit-input-placeholder,
    .color-white textarea::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.5); }
  .color-white input[type="text"],
  .color-white input[type="email"],
  .color-white input[type="password"],
  .color-white input[type="number"],
  .color-white input[type="search"],
  .color-white input[type="tel"],
  .color-white select,
  .color-white textarea {
    background: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.05); }

textarea {
  min-height: 7em;
  resize: none !important; }

/* ==========================================================================
   Ninja Forms Styling -- layout/_forms.scss
   ========================================================================== */
.nf-form-cont .nf-before-form-content {
  margin-bottom: 1em; }
.nf-form-cont .nf-form-content {
  padding: 0 !important; }
.nf-form-cont .ninja-forms-field:not(.btn) {
  padding: 1em 3.25em 1em 1em !important;
  height: auto !important; }
.nf-form-cont input, .nf-form-cont textarea, .nf-form-cont select {
  background: rgba(0, 0, 0, 0.05) !important;
  border: rgba(0, 0, 0, 0.05) !important;
  color: #000000 !important;
  font-size: 16px;
  line-height: 1.5; }
  @media only screen and (min-width: 550px) {
    .nf-form-cont input, .nf-form-cont textarea, .nf-form-cont select {
      font-size: 16px !important; } }
  @media only screen and (min-width: 700px) {
    .nf-form-cont input, .nf-form-cont textarea, .nf-form-cont select {
      font-size: 16px !important; } }
  @media only screen and (min-width: 1000px) {
    .nf-form-cont input, .nf-form-cont textarea, .nf-form-cont select {
      font-size: 17px !important; } }
  @media only screen and (min-width: 1200px) {
    .nf-form-cont input, .nf-form-cont textarea, .nf-form-cont select {
      font-size: 18px !important; } }
  @media only screen and (min-width: 1400px) {
    .nf-form-cont input, .nf-form-cont textarea, .nf-form-cont select {
      font-size: 19px !important; } }
  @media only screen and (min-width: 1600px) {
    .nf-form-cont input, .nf-form-cont textarea, .nf-form-cont select {
      font-size: 20px !important; } }
  @media only screen and (min-width: 1800px) {
    .nf-form-cont input, .nf-form-cont textarea, .nf-form-cont select {
      font-size: 21px !important; } }
  .nf-form-cont input:focus,
  .nf-form-cont textarea:focus,
  .nf-form-cont select:focus {
    border: solid 1px #d9a00e !important; }
  .color-white .nf-form-cont input,
  .color-white .nf-form-cont textarea,
  .color-white .nf-form-cont select {
    color: #ffffff !important;
    background: rgba(255, 255, 255, 0.05) !important;
    border-color: rgba(255, 255, 255, 0.05) !important; }
.nf-form-cont textarea {
  display: block; }
.nf-form-cont select {
  position: relative;
  z-index: 2; }
  .nf-form-cont select::-ms-expand {
    display: none; }
.nf-form-cont .list-select-wrap .nf-field-element:after,
.nf-form-cont .listcountry-wrap .nf-field-element:after {
  content: "\f078" !important;
  position: absolute !important;
  line-height: 30px !important;
  font-family: "Font Awesome 5 Pro" !important;
  font-size: 15px !important;
  color: #d9a00e;
  background: transparent;
  left: auto;
  right: 1.5em !important;
  top: 50%;
  height: 30px !important;
  margin-top: -15px;
  z-index: 1; }
.nf-form-cont .nf-error .nf-field-element:after,
.nf-form-cont .nf-pass .nf-field-element:after {
  top: 50% !important;
  background: transparent !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-top: -15px; }
.nf-form-cont .nf-error.textarea-wrap .nf-field-element:after,
.nf-form-cont .nf-pass.textarea-wrap .nf-field-element:after {
  top: 30px !important; }
.nf-form-cont .nf-error-msg {
  color: #fd0b0b; }
.nf-form-cont .nf-error .ninja-forms-field {
  border-color: #58cb72 !important; }
.nf-form-cont .nf-error .nf-field-element:after {
  color: #58cb72 !important; }
.nf-form-cont .nf-error .ninja-forms-field {
  border-color: #fd0b0b !important; }
.nf-form-cont .nf-error .nf-field-element:after {
  color: #fd0b0b !important; }
.nf-form-cont input[type="radio"].ninja-forms-field,
.nf-form-cont input[type="checkbox"].ninja-forms-field {
  padding: 0 !important;
  border: 0 !important;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  cursor: pointer;
  margin-right: 10px; }
  .nf-form-cont input[type="radio"].ninja-forms-field:focus,
  .nf-form-cont input[type="checkbox"].ninja-forms-field:focus {
    outline: none; }
  .nf-form-cont input[type="radio"].ninja-forms-field:checked,
  .nf-form-cont input[type="checkbox"].ninja-forms-field:checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent; }
  .nf-form-cont input[type="radio"].ninja-forms-field:after, .nf-form-cont input[type="radio"].ninja-forms-field:before,
  .nf-form-cont input[type="checkbox"].ninja-forms-field:after,
  .nf-form-cont input[type="checkbox"].ninja-forms-field:before {
    content: "";
    position: absolute; }
  .nf-form-cont input[type="radio"].ninja-forms-field:after, .nf-form-cont input[type="checkbox"].ninja-forms-field:after {
    border: 2px solid rgba(0, 0, 0, 0.05);
    top: -1px;
    left: 0px;
    width: 21px;
    height: 21px;
    box-sizing: border-box; }
    .color-white .nf-form-cont input[type="radio"].ninja-forms-field:after,
    .color-white .nf-form-cont input[type="checkbox"].ninja-forms-field:after {
      border-color: rgba(255, 255, 255, 0.05); }
  .nf-form-cont input[type="radio"].ninja-forms-field:before,
  .nf-form-cont input[type="checkbox"].ninja-forms-field:before {
    top: 4px;
    left: 6px;
    width: 9px;
    height: 9px;
    z-index: 18; }
  .nf-form-cont input[type="radio"].ninja-forms-field:checked:after, .nf-form-cont input[type="checkbox"].ninja-forms-field:checked:after {
    border-color: #000000;
    background-color: #000000; }
    .color-white .nf-form-cont input[type="radio"].ninja-forms-field:checked:after,
    .color-white .nf-form-cont input[type="checkbox"].ninja-forms-field:checked:after {
      border-color: #ffffff;
      background-color: #ffffff; }
  .nf-form-cont input[type="radio"].ninja-forms-field:checked:before, .nf-form-cont input[type="checkbox"].ninja-forms-field:checked:before {
    background-color: #ffffff; }
    .color-white .nf-form-cont input[type="radio"].ninja-forms-field:checked:before,
    .color-white .nf-form-cont input[type="checkbox"].ninja-forms-field:checked:before {
      background-color: #000000; }
.nf-form-cont input[type="checkbox"].ninja-forms-field:after {
  border-radius: 0.15em; }
.nf-form-cont input[type="checkbox"].ninja-forms-field:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: transparent;
  background: transparent !important;
  font-size: 10px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased; }
.nf-form-cont input[type="checkbox"].ninja-forms-field:checked::before {
  color: #ffffff; }
  .color-white .nf-form-cont input[type="checkbox"].ninja-forms-field:checked::before {
    color: #000000; }
.nf-form-cont .checkbox-wrap .nf-field-label {
  padding-left: 0; }
  .nf-form-cont .checkbox-wrap .nf-field-label label {
    margin-left: 1em; }
.nf-form-cont .checkbox-wrap input[type="checkbox"] {
  top: 0.1em;
  position: absolute; }
.nf-form-cont input[type="radio"].ninja-forms-field:after, .nf-form-cont input[type="radio"].ninja-forms-field:before {
  border-radius: 50%; }
.nf-form-cont input[type="radio"].ninja-forms-field:before {
  top: 5px;
  left: 6px; }
.nf-form-cont input[type="radio"].ninja-forms-field:checked::before {
  background: #ffffff; }
.nf-form-cont .listradio-wrap ul li input,
.nf-form-cont .listcheckbox-wrap ul li input {
  margin-top: 0.1em; }
.nf-form-cont .listradio-wrap ul li label,
.nf-form-cont .listcheckbox-wrap ul li label {
  margin-left: calc(21px + 1em); }
.nf-form-cont .nf-multi-cell .nf-row {
  margin-left: -0.5em;
  margin-right: -0.5em; }
.nf-form-cont .nf-multi-cell .nf-cell {
  padding: 0 0.5em; }
.nf-form-cont .nf-before-form-content {
  display: none; }

/*  ==========================================================================
	Basic layout classes -- layout/layout.scss
	========================================================================== */
html {
  height: -webkit-fill-available; }

body {
  font-family: "sofia-pro", sans-serif;
  margin: 0;
  padding: 0;
  color: #000000;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  max-width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: #ffffff; }

.bd {
  margin: 0 7.14%;
  position: relative;
  min-height: 1px; }
  @media only screen and (min-width: 1800px) {
    .bd {
      margin: 0 auto;
      max-width: calc(1800px / 100 * (100 - (7.14 * 2))); } }
  .bd:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both; }

.visuallyhidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.clearfix {
  *zoom: 1; }
  .clearfix::after {
    content: "";
    display: table;
    clear: both; }

/*  ==========================================================================
	Backgrounds -- layout/layout.scss
	========================================================================== */
.bg-gold {
  background-color: #d9a00e; }
.bg-light-gold {
  background-color: rgba(217, 160, 14, 0.1); }
.bg-white {
  background-color: #ffffff; }
.bg-black {
  background-color: #000000; }
.bg-grey {
  background-color: #888; }

/*  ==========================================================================
	Margin & Padding -- layout/layout.scss
	========================================================================== */
.pt {
  padding-top: 3.5rem; }
  @media only screen and (min-width: 550px) {
    .pt {
      padding-top: 4rem; } }
  @media only screen and (min-width: 700px) {
    .pt {
      padding-top: 5rem; } }
  @media only screen and (min-width: 1000px) {
    .pt {
      padding-top: 6rem; } }
  @media only screen and (min-width: 1200px) {
    .pt {
      padding-top: 7rem; } }

.pb {
  padding-bottom: 3.5rem; }
  @media only screen and (min-width: 550px) {
    .pb {
      padding-bottom: 4rem; } }
  @media only screen and (min-width: 700px) {
    .pb {
      padding-bottom: 5rem; } }
  @media only screen and (min-width: 1000px) {
    .pb {
      padding-bottom: 6rem; } }
  @media only screen and (min-width: 1200px) {
    .pb {
      padding-bottom: 7rem; } }

.pt--half {
  padding-top: calc(3.5rem / 2); }
  @media only screen and (min-width: 550px) {
    .pt--half {
      padding-top: calc(4rem / 2); } }
  @media only screen and (min-width: 700px) {
    .pt--half {
      padding-top: calc(5rem / 2); } }
  @media only screen and (min-width: 1000px) {
    .pt--half {
      padding-top: calc(6rem / 2); } }
  @media only screen and (min-width: 1200px) {
    .pt--half {
      padding-top: calc(7rem / 2); } }

.pb--half {
  padding-bottom: calc(3.5rem / 2); }
  @media only screen and (min-width: 550px) {
    .pb--half {
      padding-bottom: calc(4rem / 2); } }
  @media only screen and (min-width: 700px) {
    .pb--half {
      padding-bottom: calc(5rem / 2); } }
  @media only screen and (min-width: 1000px) {
    .pb--half {
      padding-bottom: calc(6rem / 2); } }
  @media only screen and (min-width: 1200px) {
    .pb--half {
      padding-bottom: calc(7rem / 2); } }

.pt--double {
  padding-top: calc(3.5rem * 2); }
  @media only screen and (min-width: 550px) {
    .pt--double {
      padding-top: calc(4rem * 2); } }
  @media only screen and (min-width: 700px) {
    .pt--double {
      padding-top: calc(5rem * 2); } }
  @media only screen and (min-width: 1000px) {
    .pt--double {
      padding-top: calc(6rem * 2); } }
  @media only screen and (min-width: 1200px) {
    .pt--double {
      padding-top: calc(7rem * 2); } }

.pb--double {
  padding-bottom: calc(3.5rem * 2); }
  @media only screen and (min-width: 550px) {
    .pb--double {
      padding-bottom: calc(4rem * 2); } }
  @media only screen and (min-width: 700px) {
    .pb--double {
      padding-bottom: calc(5rem * 2); } }
  @media only screen and (min-width: 1000px) {
    .pb--double {
      padding-bottom: calc(6rem * 2); } }
  @media only screen and (min-width: 1200px) {
    .pb--double {
      padding-bottom: calc(7rem * 2); } }

.pt--em {
  padding-top: 1em; }

.pb--em {
  padding-bottom: 1em; }

.p--none {
  padding: 0; }

.pt--none {
  padding-top: 0; }

.pb--none {
  padding-bottom: 0; }

.mt {
  margin-top: 3.5rem; }
  @media only screen and (min-width: 550px) {
    .mt {
      margin-top: 4rem; } }
  @media only screen and (min-width: 700px) {
    .mt {
      margin-top: 5rem; } }
  @media only screen and (min-width: 1000px) {
    .mt {
      margin-top: 6rem; } }
  @media only screen and (min-width: 1200px) {
    .mt {
      margin-top: 7rem; } }

.mb {
  margin-bottom: 3.5rem; }
  @media only screen and (min-width: 550px) {
    .mb {
      margin-bottom: 4rem; } }
  @media only screen and (min-width: 700px) {
    .mb {
      margin-bottom: 5rem; } }
  @media only screen and (min-width: 1000px) {
    .mb {
      margin-bottom: 6rem; } }
  @media only screen and (min-width: 1200px) {
    .mb {
      margin-bottom: 7rem; } }

.mt--half {
  margin-top: calc(3.5rem / 2); }
  @media only screen and (min-width: 550px) {
    .mt--half {
      margin-top: calc(4rem / 2); } }
  @media only screen and (min-width: 700px) {
    .mt--half {
      margin-top: calc(5rem / 2); } }
  @media only screen and (min-width: 1000px) {
    .mt--half {
      margin-top: calc(6rem / 2); } }
  @media only screen and (min-width: 1200px) {
    .mt--half {
      margin-top: calc(7rem / 2); } }

.mb--half {
  margin-bottom: calc(3.5rem / 2); }
  @media only screen and (min-width: 550px) {
    .mb--half {
      margin-bottom: calc(4rem / 2); } }
  @media only screen and (min-width: 700px) {
    .mb--half {
      margin-bottom: calc(5rem / 2); } }
  @media only screen and (min-width: 1000px) {
    .mb--half {
      margin-bottom: calc(6rem / 2); } }
  @media only screen and (min-width: 1200px) {
    .mb--half {
      margin-bottom: calc(7rem / 2); } }

.mt--double {
  margin-top: calc(3.5rem * 2); }
  @media only screen and (min-width: 550px) {
    .mt--double {
      margin-top: calc(4rem * 2); } }
  @media only screen and (min-width: 700px) {
    .mt--double {
      margin-top: calc(5rem * 2); } }
  @media only screen and (min-width: 1000px) {
    .mt--double {
      margin-top: calc(6rem * 2); } }
  @media only screen and (min-width: 1200px) {
    .mt--double {
      margin-top: calc(7rem * 2); } }

.mb--double {
  margin-bottom: calc(3.5rem * 2); }
  @media only screen and (min-width: 550px) {
    .mb--double {
      margin-bottom: calc(4rem * 2); } }
  @media only screen and (min-width: 700px) {
    .mb--double {
      margin-bottom: calc(5rem * 2); } }
  @media only screen and (min-width: 1000px) {
    .mb--double {
      margin-bottom: calc(6rem * 2); } }
  @media only screen and (min-width: 1200px) {
    .mb--double {
      margin-bottom: calc(7rem * 2); } }

.mt--em {
  margin-top: 1em; }

.mb--em {
  margin-bottom: 1em; }

.m--none {
  margin: 0; }

.mt--none {
  margin-top: 0; }

.mb--none {
  margin-bottom: 0; }

/*  ==========================================================================
	Accents 
  -- Buttons & Text colours are in _links-buttons.scss & _typography.scss
  -- All other accents are below 
	========================================================================== */
.bg-accent {
  background-color: #000000;
  color: #ffffff; }
  .color-white .bg-accent {
    background-color: #ffffff;
    color: #000000; }

.accent-gold .bg-accent {
  background-color: #d9a00e; }
.accent-light-gold .bg-accent {
  background-color: rgba(217, 160, 14, 0.1); }
.accent-white .bg-accent {
  background-color: #ffffff; }
.accent-black .bg-accent {
  background-color: #000000; }
.accent-grey .bg-accent {
  background-color: #888; }

/*  ==========================================================================
	images
	========================================================================== */
.mediaimg {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15); }
  .color-white .mediaimg {
    background-color: rgba(255, 255, 255, 0.15); }
  .mediaimg .mediaimg__img {
    max-width: 100%;
    width: 100%; }
    .mediaimg .mediaimg__img--bg {
      padding-top: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      .mediaimg .mediaimg__img--bg--cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 0; }
    .mediaimg .mediaimg__img--inline {
      display: block;
      height: auto; }
    .mediaimg .mediaimg__img--icon {
      padding-top: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }

/*  ==========================================================================
  Lazy loading
	========================================================================== */
.lazy {
  opacity: 0;
  -webkit-transition: opacity 150ms cubic-bezier(0.65, 0, 0.35, 1);
  transition: opacity 150ms cubic-bezier(0.65, 0, 0.35, 1); }
  .lazy.loaded {
    opacity: 1; }

/*  ==========================================================================
	Page
	========================================================================== */
.pageheader {
  position: relative;
  /* pageheader sizes */
  /* pageheader--img */
  /* pageheader--video */
  /* pageheader--post or postheader */
  /* pageheader--person */ }
  .pageheader .pageheader__bd {
    position: relative;
    z-index: 2; }
  .pageheader .pageheader__heading {
    margin: 0 auto;
    max-width: 12em; }
  .pageheader .pageheader__leader {
    margin: 0.5em auto 0 auto;
    max-width: 30em; }
  .pageheader .pageheader__cta {
    margin-top: 2.5em; }
  .pageheader .pageheader__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    overflow: hidden; }
  .pageheader--size--large .pageheader__body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    box-sizing: border-box;
    min-height: calc(100vh - 4rem); }
    @media only screen and (min-width: 550px) {
      .pageheader--size--large .pageheader__body {
        min-height: calc(100vh - 4rem); } }
    @media only screen and (min-width: 700px) {
      .pageheader--size--large .pageheader__body {
        min-height: calc(100vh - 5rem); } }
    @media only screen and (min-width: 1000px) {
      .pageheader--size--large .pageheader__body {
        min-height: calc(100vh - 5rem); } }
    @media only screen and (min-width: 1200px) {
      .pageheader--size--large .pageheader__body {
        min-height: calc(100vh - 6rem); } }
    @media only screen and (min-width: 1400px) {
      .pageheader--size--large .pageheader__body {
        min-height: calc(100vh - 6rem); } }
    @media only screen and (min-width: 1600px) {
      .pageheader--size--large .pageheader__body {
        min-height: calc(100vh - 6rem); } }
  .pageheader--img .pageheader__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1; }
    .pageheader--img .pageheader__img .mediaimg__img.loaded {
      opacity: 0.66; }
  .pageheader--video .pageheader__video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    opacity: 0.66;
    -webkit-transition: opacity 1000ms cubic-bezier(0.33, 1, 0.68, 1) 0ms;
    transition: opacity 1000ms cubic-bezier(0.33, 1, 0.68, 1) 0ms; }
    .pageheader--video .pageheader__video iframe {
      width: 100vw;
      height: 56.25vw;
      /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 100vh;
      min-width: 177.77vh;
      /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .pageheader--post .pageheader__categories {
    margin-bottom: 2em; }
  .pageheader--post .pageheader__posted {
    margin-top: 2em; }
  .pageheader--post .pageheader__media {
    background-color: rgba(0, 0, 0, 0.15); }
    .pageheader--post .pageheader__media .mediaimg,
    .pageheader--post .pageheader__media .mediavideo {
      box-shadow: 60px 40px 120px rgba(0, 0, 0, 0.15); }
  .pageheader--post .pageheader__socialicons {
    position: relative;
    z-index: 2; }
  .pageheader--post.pageheader--media .pageheader__socialicons .socialicons:before {
    display: none; }
  .pageheader--post.pageheader--media .pageheader__socialicons + .pageheader__media {
    margin-top: calc(-3.25rem / 2); }
  .pageheader--person .pageheader__content .pageheader__profileimg {
    max-width: 5em;
    border: 2px solid #ffffff;
    margin: 0 auto 2em auto; }
  .pageheader--person .pageheader__content .pageheader__personrole {
    margin: 0.5em 0 0 0; }
  .pageheader--person .pageheader__social {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    -webkit-transform: translateX(-50%) translateY(50%);
        -ms-transform: translateX(-50%) translateY(50%);
            transform: translateX(-50%) translateY(50%); }
    .pageheader--person .pageheader__social .socialicons {
      margin: 0; }
      .pageheader--person .pageheader__social .socialicons:before {
        display: none; }

/*  ==========================================================================
	content -- layout/layout.scss
	========================================================================== */
.contentarea h1,
.contentarea h2,
.contentarea h3,
.contentarea h4,
.contentarea h5,
.contentarea h6 {
  margin-top: 1em; }
.contentarea .aligncenter {
  margin: 1em auto;
  display: block; }
.contentarea .alignleft,
.contentarea .alignright {
  margin-top: 1em;
  margin-bottom: 1em; }
  @media only screen and (min-width: 700px) {
    .contentarea .alignleft,
    .contentarea .alignright {
      width: 40% !important; } }
.contentarea .alignleft {
  float: left;
  margin-right: 2em; }
.contentarea .alignright {
  float: right;
  margin-left: 2em; }
.contentarea .alignnone {
  width: 100% !important;
  height: auto;
  margin: 1.5em 0; }
  @media only screen and (min-width: 550px) {
    .contentarea .alignnone {
      margin: 2em 0; } }
  .contentarea .alignnone img {
    width: 100%; }
.contentarea .aligncenter,
.contentarea .alignleft,
.contentarea .alignright,
.contentarea .alignnone {
  max-width: 100%; }
.contentarea .wp-caption.aligncenter {
  margin-bottom: 0; }

/*  ==========================================================================
	List styling -- layout/layout.scss
	========================================================================== */
.list-style,
.contentarea ul,
.contentarea ol {
  list-style: none;
  margin-left: 2rem; }
  @media only screen and (min-width: 1000px) {
    .list-style,
    .contentarea ul,
    .contentarea ol {
      margin-left: 3rem; } }
  .list-style li,
  .contentarea ul li,
  .contentarea ol li {
    position: relative; }
    .list-style li:before, .contentarea ul li:before, .contentarea ol li:before {
      font-size: 0.65em;
      color: #000000;
      top: 0.45em;
      position: absolute;
      left: 0;
      margin-left: -1.25rem;
      opacity: 0.66; }
      @media only screen and (min-width: 1000px) {
        .list-style li:before, .contentarea ul li:before, .contentarea ol li:before {
          margin-left: -1.75rem; } }
      .color-white .list-style li:before,
      .color-white .contentarea ul li:before,
      .color-white .contentarea ol li:before {
        color: #ffffff; }

.list-style--bullets li,
.contentarea ul li {
  position: relative; }
  .list-style--bullets li:before,
  .contentarea ul li:before {
    content: "\f054";
    font-family: "Font Awesome 5 Pro"; }

.list-style--numbers li,
.contentarea ol li {
  counter-increment: list-no; }
  .list-style--numbers li::before,
  .contentarea ol li::before {
    content: counter(list-no) ".";
    top: 0.35em;
    font-size: 0.75em; }

.accent-gold .list-style li:before,
.accent-gold .contentarea ul li:before,
.accent-gold .contentarea ol li:before {
  color: #d9a00e; }
.accent-light-gold .list-style li:before,
.accent-light-gold .contentarea ul li:before,
.accent-light-gold .contentarea ol li:before {
  color: rgba(217, 160, 14, 0.1); }
.accent-white .list-style li:before,
.accent-white .contentarea ul li:before,
.accent-white .contentarea ol li:before {
  color: #ffffff; }
.accent-black .list-style li:before,
.accent-black .contentarea ul li:before,
.accent-black .contentarea ol li:before {
  color: #000000; }
.accent-grey .list-style li:before,
.accent-grey .contentarea ul li:before,
.accent-grey .contentarea ol li:before {
  color: #888; }

/*  ==========================================================================
	Embed styling -- layout/layout.scss
	========================================================================== */
.embedwrapper {
  position: relative;
  margin: 1.5em 0; }
  @media only screen and (min-width: 550px) {
    .embedwrapper {
      margin: 2em 0; } }

/*  ==========================================================================
	Tiles -- layout/layout.scss
	========================================================================== */
.tile {
  position: relative; }
  .tile .tile__heading {
    line-height: 1.2; }
  .tile .tile__img {
    -webkit-transition: box-shadow 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
    transition: box-shadow 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s; }
  .tile:hover .tile__img {
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.15); }
  .tile:hover .o-50, .tile:hover .contentarea .wp-caption .wp-caption-text, .contentarea .wp-caption .tile:hover .wp-caption-text,
  .tile:hover .o-33 {
    opacity: 1; }
  .tile--post .tile__heading {
    padding-right: 1em; }
  .tile--post .tile__categories {
    padding-right: 1em; }
  .tile--person .tile__content {
    padding-right: 1em; }

/*  ==========================================================================
	paging -- layout/layout.scss
	========================================================================== */
.paging--default {
  position: relative; }
  @media only screen and (min-width: 700px) {
    .paging--default {
      border-bottom: solid 1px rgba(0, 0, 0, 0.15);
      border-top: solid 1px rgba(0, 0, 0, 0.15); } }
  .paging--default .paging__numbers {
    display: none; }
    @media only screen and (min-width: 700px) {
      .paging--default .paging__numbers {
        display: block; } }
    .paging--default .paging__numbers a,
    .paging--default .paging__numbers span {
      padding: 1.5em;
      border: none;
      border-right: solid 1px rgba(0, 0, 0, 0.15);
      display: block;
      float: left;
      min-width: 1.4em;
      text-align: center; }
    .paging--default .paging__numbers .current {
      color: #888; }
  .paging--default .paging__nextprev {
    text-align: center;
    padding: 0.5em; }
    @media only screen and (min-width: 700px) {
      .paging--default .paging__nextprev {
        position: absolute;
        padding: 0;
        right: 0;
        top: 50%;
        margin-top: -1.7em; } }
    .paging--default .paging__nextprev .btn {
      margin: 0; }
    .paging--default .paging__nextprev .paging__link--next .btn {
      margin-left: 0.75em; }
.paging--ajax {
  text-align: center; }

/*  ==========================================================================
	Search results page -- layout/layout.scss
	========================================================================== */
.searchform .searchform__input {
  position: relative; }
  .searchform .searchform__input input {
    background: none;
    padding-right: 4em;
    position: relative;
    z-index: 1; }
  .searchform .searchform__input .searchform__submit {
    padding: 1em 1.25em;
    border: none;
    position: absolute;
    height: 100%;
    z-index: 2;
    top: 1px;
    right: 1px;
    background: none;
    text-align: center;
    color: #000000; }
    .searchform .searchform__input .searchform__submit:hover {
      color: #d9a00e; }

/*  ==========================================================================
	Grid Carousels -- layout/layout.scss
	========================================================================== */
.row--carousel {
  margin-bottom: 0; }
  .row--carousel .owl-carousel .col {
    width: 100%;
    margin-bottom: 0; }
  .row--carousel .owl-carousel .owl-stage-outer {
    will-change: height;
    overflow: visible; }
    .row--carousel .owl-carousel .owl-stage-outer .owl-stage {
      overflow: hidden;
      height: 100% !important; }
  .row--carousel .owl-carousel .owl-nav,
  .row--carousel .owl-carousel .owl-dots {
    position: absolute;
    top: -1.5rem;
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
    right: 0;
    margin-right: 0.5em; }
    @media only screen and (min-width: 550px) {
      .row--carousel .owl-carousel .owl-nav,
      .row--carousel .owl-carousel .owl-dots {
        margin-right: 0.5em; } }
    @media only screen and (min-width: 700px) {
      .row--carousel .owl-carousel .owl-nav,
      .row--carousel .owl-carousel .owl-dots {
        margin-right: 0.75em; } }
    @media only screen and (min-width: 1000px) {
      .row--carousel .owl-carousel .owl-nav,
      .row--carousel .owl-carousel .owl-dots {
        margin-right: 0.75em; } }
    @media only screen and (min-width: 1200px) {
      .row--carousel .owl-carousel .owl-nav,
      .row--carousel .owl-carousel .owl-dots {
        margin-right: 0.75em; } }
    @media only screen and (min-width: 1400px) {
      .row--carousel .owl-carousel .owl-nav,
      .row--carousel .owl-carousel .owl-dots {
        margin-right: 0.75em; } }
    @media only screen and (min-width: 1600px) {
      .row--carousel .owl-carousel .owl-nav,
      .row--carousel .owl-carousel .owl-dots {
        margin-right: 0.75em; } }
  .row--carousel .owl-carousel .owl-nav .owl-next,
  .row--carousel .owl-carousel .owl-nav .owl-prev {
    position: relative;
    border-radius: 0;
    width: 3.5rem;
    height: 3.5rem; }
    .row--carousel .owl-carousel .owl-nav .owl-next i,
    .row--carousel .owl-carousel .owl-nav .owl-prev i {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .row--carousel .owl-carousel .owl-nav .owl-next:focus,
    .row--carousel .owl-carousel .owl-nav .owl-prev:focus {
      outline: none; }
    .row--carousel .owl-carousel .owl-nav .owl-next.disabled i,
    .row--carousel .owl-carousel .owl-nav .owl-prev.disabled i {
      opacity: 0.25; }
  .row--carousel .owl-carousel .owl-nav .owl-prev {
    border-right: 1px solid rgba(0, 0, 0, 0.15); }
    .color-white .row--carousel .owl-carousel .owl-nav .owl-prev {
      border-color: rgba(255, 255, 255, 0.15); }
  .row--carousel .owl-carousel .owl-dots .owl-dot {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    width: 10px;
    height: 10px;
    border: 1px solid rgba(0, 0, 0, 0.33);
    margin-left: 5px;
    border-radius: 50%; }
    .row--carousel .owl-carousel .owl-dots .owl-dot.active {
      background-color: #000000;
      border-color: #000000; }
    .color-white .row--carousel .owl-carousel .owl-dots .owl-dot {
      border-color: rgba(255, 255, 255, 0.33); }
      .color-white .row--carousel .owl-carousel .owl-dots .owl-dot.active {
        background-color: #ffffff;
        border-color: #ffffff; }
  .row--carousel .owl-carousel[data-autoHeight="disable"] .owl-stage {
    position: relative; }

/*  ==========================================================================
	Contact Details -- layout/layout.scss
	========================================================================== */
.contactdetails .contactdetails__col {
  border-top: 1px solid rgba(0, 0, 0, 0.15); }
  .color-white .contactdetails .contactdetails__col {
    border-color: rgba(255, 255, 255, 0.15); }
  @media only screen and (min-width: 700px) {
    .contactdetails .contactdetails__col {
      margin-top: 0; } }
  .contactdetails .contactdetails__col .contactdetails__item {
    margin-top: 2rem; }
  .contactdetails .contactdetails__col .contactdetails__link {
    margin-top: 1em; }
.contactdetails .contactdetails__row .col:first-child .contactdetails__col {
  margin-top: 0; }

/*  ==========================================================================
	Video styling using plyr.io
	========================================================================== */
.mediavideo {
  background-color: rgba(0, 0, 0, 0.15); }
  .color-white .mediavideo {
    background-color: rgba(255, 255, 255, 0.15); }
  .mediavideo .plyr {
    overflow: visible;
    background: transparent; }
    .mediavideo .plyr iframe {
      margin-top: -1px; }
    .mediavideo .plyr .plyr__controls {
      padding: 0 !important; }
      .mediavideo .plyr .plyr__controls:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 8rem;
        background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
        bottom: 3px;
        pointer-events: none; }
      .mediavideo .plyr .plyr__controls .plyr__controls__item {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 1.15rem;
        height: 3.3rem;
        line-height: 1rem; }
        .mediavideo .plyr .plyr__controls .plyr__controls__item.plyr__tab-focus, .mediavideo .plyr .plyr__controls .plyr__controls__item:hover, .mediavideo .plyr .plyr__controls .plyr__controls__item[aria-expanded="true"] {
          color: #d9a00e;
          background: none; }
        .mediavideo .plyr .plyr__controls .plyr__controls__item.plyr__time {
          left: auto;
          right: 3.5em;
          font-size: 70%;
          font-weight: 600; }
        .mediavideo .plyr .plyr__controls .plyr__controls__item.plyr__volume {
          left: auto;
          right: 0;
          width: auto;
          min-width: 32px; }
          .mediavideo .plyr .plyr__controls .plyr__controls__item.plyr__volume .plyr__control {
            padding: 0 !important;
            background: transparent !important; }
      .mediavideo .plyr .plyr__controls .plyr__progress {
        left: 0 !important;
        margin-right: 0 !important;
        overflow: hidden; }
      .mediavideo .plyr .plyr__controls .plyr__progress__buffer {
        background: transparent !important; }
      .mediavideo .plyr .plyr__controls .plyr__progress__container {
        padding: 0 !important;
        margin: 0 !important;
        -webkit-box-flex: 0 !important;
        -webkit-flex: none !important;
            -ms-flex: none !important;
                flex: none !important;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px; }
        .mediavideo .plyr .plyr__controls .plyr__progress__container .plyr__progress {
          left: 0;
          margin: 0;
          padding: 0;
          border-radius: 0;
          height: 3px;
          /* All the same stuff for Firefox */
          /* All the same stuff for IE */ }
          .mediavideo .plyr .plyr__controls .plyr__progress__container .plyr__progress .plyr__progress__buffer {
            display: none; }
          .mediavideo .plyr .plyr__controls .plyr__progress__container .plyr__progress input[type="range"] {
            color: #d9a00e !important;
            border-radius: 0;
            margin: 0;
            height: 100%;
            width: 100%; }
          .mediavideo .plyr .plyr__controls .plyr__progress__container .plyr__progress input[type="range"]::-webkit-slider-runnable-track {
            border-radius: 0; }
          .mediavideo .plyr .plyr__controls .plyr__progress__container .plyr__progress input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            border-radius: 0;
            height: 5px;
            width: 2px;
            margin: 0;
            padding: 0;
            background-color: transparent;
            box-shadow: 0px 0px 0px #000000;
            border: 0px solid #000000; }
          .mediavideo .plyr .plyr__controls .plyr__progress__container .plyr__progress input[type="range"]::-moz-range-thumb {
            -webkit-appearance: none;
            border-radius: 0;
            height: 5px;
            width: 2px;
            margin: 0;
            padding: 0;
            background-color: transparent;
            box-shadow: 0px 0px 0px #000000;
            border: 0px solid #000000; }
          .mediavideo .plyr .plyr__controls .plyr__progress__container .plyr__progress input[type="range"]::-ms-thumb {
            -webkit-appearance: none;
            border-radius: 0;
            height: 5px;
            width: 2px;
            margin: 0;
            padding: 0;
            background-color: transparent;
            box-shadow: 0px 0px 0px #000000;
            border: 0px solid #000000; }
          .mediavideo .plyr .plyr__controls .plyr__progress__container .plyr__progress input[type="range"]::-ms-track {
            border-radius: 0; }
          .mediavideo .plyr .plyr__controls .plyr__progress__container .plyr__progress input[type="range"]::-ms-fill-lower {
            border-radius: 0; }
          .mediavideo .plyr .plyr__controls .plyr__progress__container .plyr__progress input[type="range"]::-ms-fill-upper {
            border-radius: 0; }
  .mediavideo .mediavideo__video.no-plyr {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */ }
    .mediavideo .mediavideo__video.no-plyr iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%; }

/*  ==========================================================================
	Header variables
	========================================================================== */
/*  ==========================================================================
	siteheader
	========================================================================== */
.siteheader {
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  .siteheader--padder {
    position: relative;
    visibility: hidden;
    pointer-events: none;
    z-index: 1; }
  .siteheader .siteheader__wrap {
    height: 4rem; }
    @media only screen and (min-width: 550px) {
      .siteheader .siteheader__wrap {
        height: 4rem; } }
    @media only screen and (min-width: 700px) {
      .siteheader .siteheader__wrap {
        height: 5rem; } }
    @media only screen and (min-width: 1000px) {
      .siteheader .siteheader__wrap {
        height: 5rem; } }
    @media only screen and (min-width: 1200px) {
      .siteheader .siteheader__wrap {
        height: 6rem; } }
    @media only screen and (min-width: 1400px) {
      .siteheader .siteheader__wrap {
        height: 6rem; } }
    @media only screen and (min-width: 1600px) {
      .siteheader .siteheader__wrap {
        height: 6rem; } }
    .siteheader .siteheader__wrap .siteheader__bd {
      height: 100%; }
    .siteheader .siteheader__wrap .siteheader__logo {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 10em; }
      @media only screen and (min-width: 1000px) {
        .siteheader .siteheader__wrap .siteheader__logo {
          width: 12em; } }
      .siteheader .siteheader__wrap .siteheader__logo .siteheader__logo__img {
        width: 100%;
        padding-top: 15%;
        display: block;
        /* background-image: url("img/framework/logo.svg"); */
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain; }
    .siteheader .siteheader__wrap .siteheader__mobtools {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      right: 0; }
      @media only screen and (min-width: 1000px) {
        .siteheader .siteheader__wrap .siteheader__mobtools {
          display: none; } }
    .siteheader .siteheader__wrap .sitenav {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      display: none; }
      @media only screen and (min-width: 1000px) {
        .siteheader .siteheader__wrap .sitenav {
          display: block; } }
      .siteheader .siteheader__wrap .sitenav .sitenav__item {
        position: relative; }
        .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__link {
          position: relative;
          line-height: 4rem;
          height: 4rem; }
          @media only screen and (min-width: 550px) {
            .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__link {
              line-height: 4rem;
              height: 4rem; } }
          @media only screen and (min-width: 700px) {
            .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__link {
              line-height: 5rem;
              height: 5rem; } }
          @media only screen and (min-width: 1000px) {
            .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__link {
              line-height: 5rem;
              height: 5rem; } }
          @media only screen and (min-width: 1200px) {
            .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__link {
              line-height: 6rem;
              height: 6rem; } }
          @media only screen and (min-width: 1400px) {
            .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__link {
              line-height: 6rem;
              height: 6rem; } }
          @media only screen and (min-width: 1600px) {
            .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__link {
              line-height: 6rem;
              height: 6rem; } }
          .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__link:after {
            content: "";
            height: 4px;
            width: 0;
            -webkit-transition: width 150ms cubic-bezier(0.65, 0, 0.35, 1);
            transition: width 150ms cubic-bezier(0.65, 0, 0.35, 1);
            background-color: #d9a00e;
            position: absolute;
            bottom: 0;
            left: 0; }
          .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__link:hover:after, .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__link--current:after {
            width: 100%; }
        .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__item__dropdown {
          opacity: 0;
          pointer-events: none;
          text-align: center;
          position: absolute;
          width: 14rem;
          left: 50%;
          margin-left: -7rem;
          background-color: #ffffff;
          box-shadow: 60px 40px 120px rgba(0, 0, 0, 0.15);
          -webkit-transition: opacity 150ms cubic-bezier(0.33, 1, 0.68, 1) 0ms, -webkit-transform 250ms cubic-bezier(0.33, 1, 0.68, 1) 0ms;
          transition: opacity 150ms cubic-bezier(0.33, 1, 0.68, 1) 0ms, -webkit-transform 250ms cubic-bezier(0.33, 1, 0.68, 1) 0ms;
          transition: transform 250ms cubic-bezier(0.33, 1, 0.68, 1) 0ms, opacity 150ms cubic-bezier(0.33, 1, 0.68, 1) 0ms;
          transition: transform 250ms cubic-bezier(0.33, 1, 0.68, 1) 0ms, opacity 150ms cubic-bezier(0.33, 1, 0.68, 1) 0ms, -webkit-transform 250ms cubic-bezier(0.33, 1, 0.68, 1) 0ms;
          -webkit-transform: perspective(1000px) rotateX(20deg);
                  transform: perspective(1000px) rotateX(20deg);
          -webkit-transform-origin: 50% 0%;
              -ms-transform-origin: 50% 0%;
                  transform-origin: 50% 0%; }
          .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__item__dropdown .dropdown__list {
            list-style: none;
            padding: 1em;
            margin: 0; }
            .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__item__dropdown .dropdown__list li {
              display: block; }
              .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__item__dropdown .dropdown__list li .dropdown__link {
                padding: 0.5em;
                margin: 0; }
                .siteheader .siteheader__wrap .sitenav .sitenav__item .sitenav__item__dropdown .dropdown__list li .dropdown__link:hover {
                  color: #d9a00e; }
        .siteheader .siteheader__wrap .sitenav .sitenav__item:hover .sitenav__item__dropdown {
          opacity: 1;
          pointer-events: all;
          -webkit-transform: perspective(1000px) rotateX(0deg);
                  transform: perspective(1000px) rotateX(0deg); }
      .siteheader .siteheader__wrap .sitenav--center {
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .siteheader .siteheader__wrap .sitenav--right {
        right: 0; }

/*  ==========================================================================
	mobnav
	========================================================================== */
.mobnav {
  position: relative;
  width: 100%;
  overflow: scroll;
  height: 0;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
  -webkit-transition: height 150ms cubic-bezier(0.65, 0, 0.35, 1);
  transition: height 150ms cubic-bezier(0.65, 0, 0.35, 1); }
  @media only screen and (min-width: 1000px) {
    .mobnav {
      display: none; } }
  .mobnav .sitenav {
    margin-bottom: 0; }
    .mobnav .sitenav:first-child {
      margin-top: -1.25rem; }
    .mobnav .sitenav ul {
      margin: 0; }
    .mobnav .sitenav .sitenav__item {
      display: block;
      border-top: solid 1px rgba(0, 0, 0, 0.15); }
      .mobnav .sitenav .sitenav__item:first-child {
        border-top: none; }
      .mobnav .sitenav .sitenav__item .sitenav__link {
        display: block;
        padding: 1.25rem 0;
        margin: 0; }
      .mobnav .sitenav .sitenav__item .sitenav__btn {
        margin: 1.25rem 0;
        text-align: center;
        display: block; }
      .mobnav .sitenav .sitenav__item--hasdropdown {
        position: relative; }
        .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdownarrow {
          position: absolute;
          right: 0;
          top: 0;
          padding: 1.25rem;
          cursor: pointer;
          -webkit-transition: -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
          transition: -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
          transition: transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
          transition: transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s, -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s; }
          .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdownarrow:after {
            content: "\f107";
            color: #d9a00e;
            font-family: "font awesome 5 pro";
            font-weight: 200;
            position: relative; }
        .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__link {
          position: relative; }
        .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown {
          display: none; }
          .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list {
            margin: 0 0 1.25em 0; }
            .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list li {
              display: block; }
              .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list li .dropdown__link {
                margin: 0;
                padding: 0.5em 0;
                display: block; }
                .mobnav .sitenav .sitenav__item--hasdropdown .sitenav__item__dropdown .dropdown__list li .dropdown__link:first-child {
                  padding-top: 0; }
        .mobnav .sitenav .sitenav__item--hasdropdown.sitenav__item--open .sitenav__item__dropdownarrow {
          -webkit-transform: rotate(-180deg);
              -ms-transform: rotate(-180deg);
                  transform: rotate(-180deg); }
        .mobnav .sitenav .sitenav__item--hasdropdown.sitenav__item--open .sitenav__item__dropdown {
          display: block; }
  .mobnav .mobnav__search {
    margin-top: 3rem; }

.mobnavopen .mobnav-toggle .fa-bars:before {
  content: "\f00d"; }

/*  ==========================================================================
	search
	========================================================================== */
.siteheader__search {
  position: absolute;
  width: 100%;
  top: calc(100% + 1px);
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  pointer-events: none;
  opacity: 0;
  display: none; }
  @media only screen and (min-width: 1000px) {
    .siteheader__search {
      display: block; } }
  .siteheader__search--open {
    pointer-events: auto;
    opacity: 1;
    -webkit-transition: opacity 150ms cubic-bezier(0.65, 0, 0.35, 1);
    transition: opacity 150ms cubic-bezier(0.65, 0, 0.35, 1); }

/* ==========================================================================
   Footer -- layout/_footer.scss
   ========================================================================== */
.sitefooter {
  text-align: center; }
  @media only screen and (min-width: 700px) {
    .sitefooter {
      text-align: left; } }
  .sitefooter .sitefooter__cta .sitefooter__cta__cta {
    margin-top: 1.5em; }
  .sitefooter .sitefooter__footer {
    padding: 2em 0;
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
  @media only screen and (min-width: 700px) {
    .sitefooter .sitefooter__socialnav {
      margin-top: 0;
      text-align: right; } }
  .sitefooter .sitefooter__socialnav .sitefooter__sociallink:hover {
    color: #d9a00e; }
  .color-white .sitefooter .sitefooter__footer, .sitefooter.color-white .sitefooter__footer {
    border-color: rgba(255, 255, 255, 0.15); }

/*  ==========================================================================
	COMPONENTS - _modules.scss
	========================================================================== */
/*  ==========================================================================
	COMPONENT - Text Content
	========================================================================== */
.textcontent .textcontent__preheading {
  margin-bottom: 0.5em; }
.textcontent .textcontent__cta {
  margin-top: 1.5em; }
.textcontent .textcontent__list {
  margin-top: 1.5em; }
@media only screen and (min-width: 1000px) {
  .module--left .textcontent {
    padding-right: 2.5rem; } }
@media only screen and (min-width: 1200px) {
  .module--left .textcontent {
    padding: 0 calc(7.14% * 2); } }
@media only screen and (min-width: 1400px) {
  .module--left .textcontent {
    padding: 0 20%; } }
@media only screen and (min-width: 1000px) {
  .module--right .textcontent {
    padding-left: 2.5rem; } }
@media only screen and (min-width: 1200px) {
  .module--right .textcontent {
    padding: 0 calc(7.14% * 2); } }
@media only screen and (min-width: 1400px) {
  .module--right .textcontent {
    padding: 0 20%; } }

/*  ==========================================================================
	COMPONENT - List Grid
	========================================================================== */
.listgrid .listgrid__item .listgrid__item__icon,
.listgrid .listgrid__item .listgrid__item__image {
  margin-bottom: 1.5em; }
.listgrid .listgrid__item .listgrid__item__icon {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%; }
  @media only screen and (min-width: 700px) {
    .listgrid .listgrid__item .listgrid__item__icon {
      width: 4.5rem;
      height: 4.5rem; } }
  .listgrid .listgrid__item .listgrid__item__icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
.listgrid .listgrid__item .listgrid__item__image .mediaimg__img {
  padding-top: 75%; }
@media only screen and (min-width: 550px) {
  .listgrid .listgrid__item .listgrid__item__content {
    padding-right: 1em; } }

/*  ==========================================================================
	MODULES - _modules.scss
	========================================================================== */
/*  ==========================================================================
	General Module styles
	========================================================================== */
.module--fullwidth + .module--fullwidth,
.module--bg + .module--bg,
.pageheader--bg + .module--bg {
  margin-top: -3.5rem; }
  @media only screen and (min-width: 550px) {
    .module--fullwidth + .module--fullwidth,
    .module--bg + .module--bg,
    .pageheader--bg + .module--bg {
      margin-top: -4rem; } }
  @media only screen and (min-width: 700px) {
    .module--fullwidth + .module--fullwidth,
    .module--bg + .module--bg,
    .pageheader--bg + .module--bg {
      margin-top: -5rem; } }
  @media only screen and (min-width: 1000px) {
    .module--fullwidth + .module--fullwidth,
    .module--bg + .module--bg,
    .pageheader--bg + .module--bg {
      margin-top: -6rem; } }
  @media only screen and (min-width: 1200px) {
    .module--fullwidth + .module--fullwidth,
    .module--bg + .module--bg,
    .pageheader--bg + .module--bg {
      margin-top: -7rem; } }

.bg-gold + .bg-gold {
  padding-top: 0; }

.bg-light-gold + .bg-light-gold {
  padding-top: 0; }

.bg-white + .bg-white {
  padding-top: 0; }

.bg-black + .bg-black {
  padding-top: 0; }

.bg-grey + .bg-grey {
  padding-top: 0; }

.main .module--bg:last-child {
  margin-bottom: 0; }

/*  ==========================================================================
	MODULE - Featured (this class is used for all modules that feature tiles, such as "featured posts")
	========================================================================== */
.module--featured .featured__header .featured__header__heading {
  margin-bottom: 0; }
.module--featured .featured__header .featured__header__leader {
  margin-bottom: 0;
  margin-top: 0.5em; }
.module--featured--carousel .featured__header, .module--featured--grid .featured__header {
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
.module--featured--carousel .featured__leader, .module--featured--grid .featured__leader {
  padding-right: 1em; }
.module--featured--carousel {
  overflow: hidden; }
  .module--featured--carousel .featured__header {
    min-height: 3.5rem;
    box-sizing: border-box;
    padding-right: calc((3.5rem * 2) + 1em);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end; }
    .module--featured--carousel .featured__header .carousel__nav {
      position: absolute;
      bottom: 0;
      right: 0; }
@media only screen and (min-width: 700px) {
  .module--featured--single .featured__header {
    padding-right: 1.5em; } }
.module--featured--single .featured__header .featured__header__heading {
  margin-bottom: 0.5em; }
.module--featured--single .featured__header .featured__header__leader {
  margin-top: 0; }
.module--featured--single .featured__header .btn {
  margin: 1.5em 0 0 0; }
.color-white .module--featured.module--featured--carousel .featured__header, .color-white .module--featured.module--featured--grid .featured__header, .module--featured.color-white.module--featured--carousel .featured__header, .module--featured.color-white.module--featured--grid .featured__header {
  border-color: rgba(255, 255, 255, 0.15); }

/*  ==========================================================================
	MODULE - Line / Rule
	========================================================================== */
.module--line .line {
  border-top: solid 1px rgba(0, 0, 0, 0.15);
  height: 0; }
.color-white .module--line .line, .module--line.color-white .line {
  border-color: rgba(255, 255, 255, 0.15); }

/*  ==========================================================================
	MODULE - Line Heading
	========================================================================== */
.module--line-heading .module__bd {
  border-top: solid 1px rgba(0, 0, 0, 0.15); }
.module--line-heading .line-heading__heading {
  margin-top: 1em; }
.color-white .module--line-heading .module__bd, .module--line-heading.color-white .module__bd {
  border-color: rgba(255, 255, 255, 0.15); }

/*  ==========================================================================
	MODULE - Section Heading
	========================================================================== */
.module--section-heading .section-heading__pre_heading {
  margin-bottom: 0.5em; }
.module--section-heading .section-heading__textarea {
  max-width: 25em;
  margin: 0 auto 1em auto; }

/*  ==========================================================================
	MODULE - Text Content & Media
	========================================================================== */
@media only screen and (min-width: 1000px) {
  .module--text-content-media .text-content-media__col--media {
    margin-bottom: 0; } }
.module--text-content-media .text-content-media__col--media .mediaimg,
.module--text-content-media .text-content-media__col--media .mediavideo {
  box-shadow: 60px 40px 120px rgba(0, 0, 0, 0.15); }

/*  ==========================================================================
	MODULE - Text Content & Accordion
	========================================================================== */
@media only screen and (min-width: 1000px) {
  .module--text-content-accordion .text-content-accordion__col--text {
    margin-bottom: 0; } }
.module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item {
  display: block;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }
  .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item .accordion__question {
    margin: 0;
    border-top: none;
    padding: 1.5rem 3em 1.5rem 0em;
    position: relative;
    cursor: pointer; }
    .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item .accordion__question:hover {
      opacity: 0.5; }
    .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item .accordion__question .accordion__icon {
      position: absolute;
      top: 1.75rem;
      right: 1em;
      display: block;
      line-height: 1.2;
      -webkit-transition: -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
      transition: -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
      transition: transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
      transition: transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s, -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
      color: #000000; }
  .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item .accordion__answer {
    height: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transform: translate(0, -1em);
        -ms-transform: translate(0, -1em);
            transform: translate(0, -1em);
    -webkit-transition: height 0ms linear 260ms, opacity 150ms cubic-bezier(0.33, 1, 0.68, 1), -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1);
    transition: height 0ms linear 260ms, opacity 150ms cubic-bezier(0.33, 1, 0.68, 1), -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1);
    transition: height 0ms linear 260ms, opacity 150ms cubic-bezier(0.33, 1, 0.68, 1), transform 150ms cubic-bezier(0.33, 1, 0.68, 1);
    transition: height 0ms linear 260ms, opacity 150ms cubic-bezier(0.33, 1, 0.68, 1), transform 150ms cubic-bezier(0.33, 1, 0.68, 1), -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1); }
    .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item .accordion__answer .inner {
      margin: 0;
      padding: 0em 1.5em 2rem 0em; }
      @media only screen and (min-width: 1000px) {
        .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item .accordion__answer .inner {
          padding-right: 3em; } }
      .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item .accordion__answer .inner .btn {
        margin: 0.5em 0 0 0; }
  .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item--open .accordion__question .accordion__icon {
    -webkit-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
            transform: rotate(-180deg); }
  .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item--open .accordion__answer {
    -webkit-transition: height 0ms linear 0ms, opacity 150ms cubic-bezier(0.33, 1, 0.68, 1) 10ms, -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 10ms;
    transition: height 0ms linear 0ms, opacity 150ms cubic-bezier(0.33, 1, 0.68, 1) 10ms, -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 10ms;
    transition: height 0ms linear 0ms, opacity 150ms cubic-bezier(0.33, 1, 0.68, 1) 10ms, transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 10ms;
    transition: height 0ms linear 0ms, opacity 150ms cubic-bezier(0.33, 1, 0.68, 1) 10ms, transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 10ms, -webkit-transform 150ms cubic-bezier(0.33, 1, 0.68, 1) 10ms;
    height: auto;
    opacity: 1;
    -webkit-transform: translate(0, 0em);
        -ms-transform: translate(0, 0em);
            transform: translate(0, 0em); }
.color-white .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item, .module--text-content-accordion.color-white .text-content-accordion__col--accordion .accordion .accordion__item {
  border-color: rgba(255, 255, 255, 0.15); }
  .color-white .module--text-content-accordion .text-content-accordion__col--accordion .accordion .accordion__item .accordion__icon, .module--text-content-accordion.color-white .text-content-accordion__col--accordion .accordion .accordion__item .accordion__icon {
    color: #ffffff; }

.module--text-content-accordion.accent-gold .text-content-accordion__col--accordion .accordion .accordion__item .accordion__question .accordion__icon {
  color: #d9a00e; }
.module--text-content-accordion.accent-light-gold .text-content-accordion__col--accordion .accordion .accordion__item .accordion__question .accordion__icon {
  color: rgba(217, 160, 14, 0.1); }
.module--text-content-accordion.accent-white .text-content-accordion__col--accordion .accordion .accordion__item .accordion__question .accordion__icon {
  color: #ffffff; }
.module--text-content-accordion.accent-black .text-content-accordion__col--accordion .accordion .accordion__item .accordion__question .accordion__icon {
  color: #000000; }
.module--text-content-accordion.accent-grey .text-content-accordion__col--accordion .accordion .accordion__item .accordion__question .accordion__icon {
  color: #888; }

/*  ==========================================================================
	MODULE - Text Content & List Grid
	========================================================================== */
@media only screen and (min-width: 1000px) {
  .module--text-content-listgrid .text-content-listgrid__col--text {
    margin-bottom: 0; } }

/*  ==========================================================================
	MODULE - Quote
	========================================================================== */
.module--quote {
  position: relative; }
  .module--quote .quote__content {
    position: relative;
    text-align: center; }
    @media only screen and (min-width: 1200px) {
      .module--quote .quote__content {
        text-align: left; } }
  .module--quote .quote__cite {
    display: block;
    font-style: normal;
    margin-top: 1.5em; }
  .module--quote .quote__img {
    border-radius: 50%;
    max-width: 5rem;
    margin-bottom: 1.5em;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden; }
    @media only screen and (min-width: 700px) {
      .module--quote .quote__img {
        max-width: 6rem; } }
    @media only screen and (min-width: 1200px) {
      .module--quote .quote__img {
        margin-bottom: 0;
        margin-left: -9rem;
        position: absolute;
        top: 0;
        left: 0; } }

/*  ==========================================================================
	MODULE - Call to Action
	========================================================================== */
.module--cta {
  position: relative; }
  .module--cta .cta__cta {
    margin-top: 1.5em; }

/*  ==========================================================================
	MODULE - Video
	========================================================================== */
.module--video .mediavideo {
  box-shadow: 60px 40px 120px rgba(0, 0, 0, 0.15); }

/*  ==========================================================================
	MODULE - Images
	========================================================================== */
.module--images .images__wrap {
  overflow: hidden;
  padding-top: calc(1.5rem + 10px + 2px); }

/*  ==========================================================================
	MODULE - Interview / Q & A
	========================================================================== */
.module--interview .interview__question,
.module--interview .interview__answer {
  position: relative; }
  .module--interview .interview__question .interview__tag,
  .module--interview .interview__answer .interview__tag {
    display: inline-block;
    margin-bottom: 0.5em; }
    .module--interview .interview__question .interview__tag::after,
    .module--interview .interview__answer .interview__tag::after {
      content: "-";
      margin-left: 0.5em; }
    @media only screen and (min-width: 1000px) {
      .module--interview .interview__question .interview__tag,
      .module--interview .interview__answer .interview__tag {
        position: absolute;
        left: -7em;
        margin-bottom: 0; } }
.module--interview .interview__question {
  margin-bottom: 1.5em; }
  .module--interview .interview__question .interview__question__text {
    margin-bottom: 0; }
.module--interview .interview__row .col:last-child .interview__item {
  margin-bottom: 0; }
